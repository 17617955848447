import env from '@/config/env';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['home', 'common', 'etc'],
    defaultNS: 'common',
    // lng: 'ko',
    supportedLngs: ['en', 'ko'],
    fallbackLng: ['en', 'ko'],
    debug: import.meta.env.DEV,
    detection: {
      lookupQuerystring: 'lng',
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
    },
    backend: {
      loadPath: `${env.CDN}/locales/{{lng}}/{{ns}}.json`,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'h3', 'b', 'strong', 'em', 'p', 'img', '&nbsp;'],
    },
  });

export default i18n;
