import footerCi from '@images/footer/footer-ci.png';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavHashLink } from 'react-router-hash-link';

type Props = {
  inView: 'stock' | 'financial' | 'earnings' | 'disclosure' | 'announcement';
};
export function StickyBookMark({ inView }: Props) {
  const { t } = useTranslation('investors');
  const { i18n } = useTranslation();
  return (
    <div className="mark-holder">
      <div className={`bar in-view--${inView}`}>
        <NavHashLink to={'#stock'} className="btn-go stock" smooth>
          <span>{t('stock')}</span>
        </NavHashLink>
        <NavHashLink to={'#financial'} className="btn-go financial" smooth>
          <span>{t('financials')}</span>
        </NavHashLink>
        <NavHashLink to={'#earningsrelease'} className="btn-go earnings" smooth>
          <span>{t('earnings')}</span>
        </NavHashLink>
        {i18n.language === 'ko' && (
          <>
            <NavHashLink to={'#disclosure'} className="btn-go disclosure" smooth>
              <span>{t('disclosure')}</span>
            </NavHashLink>
            <NavHashLink to={'#announce'} className="btn-go announcement" smooth>
              <span>{t('announcement')}</span>
            </NavHashLink>
          </>
        )}
      </div>
    </div>
  );
}
