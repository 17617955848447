import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { PageHeader } from '@/components/PageHeader';
import { StickyGoTop } from '@/components/StickyGoTop';
import Welfare from '@/components/Welfare';
import { API_PATH } from '@/constants';
import { useLeverAPI } from '@/hooks/useLeverAPI';
import { useScroll } from '@/hooks/useScroll';
import { RecruitModel } from '@/model/recruit-model';
import classNames from 'classnames/bind';
import styles from './RecruitView.module.scss';
import { RecruitCard } from './RecruitCard';

let cx = classNames.bind(styles);

type Props = {
  id: string;
};

export function RecruitView({ id }: Props) {
  const { scroll } = useScroll();

  const [posting, setPosting] = useState<RecruitModel.IPostingRes>();
  const [similarPostings, setSimilarPostings] = useState<RecruitModel.IPostingRes[]>([]);
  const { totalPosting, error } = useLeverAPI(API_PATH['lever']);

  const getPostingById = (id: string): RecruitModel.IPostingRes | undefined => {
    return totalPosting?.find((el) => el.id === id);
  };

  const navigate = useNavigate();
  const navigateToPost = (id: string) => {
    navigate(`?id=${id}`);
  };

  useEffect(() => {
    if (!id) return;
    setPosting(getPostingById(id));
  }, [id, totalPosting]);

  useEffect(() => {
    if (!posting || !totalPosting) return;

    const sameTeamPosts = totalPosting
      .filter((p) => p.id !== posting.id && p.categories.team === posting.categories.team)
      .slice(0, 3);

    setSimilarPostings(sameTeamPosts);
  }, [posting, totalPosting]);

  return (
    <>
      <PageHeader title="CAREERS">
        <BreadCrumbItem content="CAREERS" url="/careers" />
        <BreadCrumbItem content="채용 공고" url="/careers/recruit" />
      </PageHeader>
      <StickyGoTop className={cx('go-top')}>
        {posting && (
          <div className={`${cx('view-header')} ${scroll ? cx('scrolled') : ''}`}>
            <div className={cx('header-box')}>
              <div className={cx('title-box')}>
                <div className={`${cx('team-box')} ${scroll ? cx('scrolled') : ''}`}>
                  {posting.categories.department &&
                    posting.categories.department !== 'Other teams' && ( // department 'Other teams' 예외처리
                      <a className={cx('department')}>{posting.categories.department}</a>
                    )}
                  <a className={cx('team')}>{posting.categories.team}</a>
                </div>
                <p className={cx('title')}>{posting.text}</p>
              </div>

              <div className={cx('apply-btn')}>
                <a
                  href={`https://jobs.lever.co/neowiz/${posting.id}/apply`}
                  className={cx('btn-more')}
                  target="_blank">
                  <span>지원하기</span>
                  <i></i>
                </a>
              </div>
            </div>
          </div>
        )}
        <div className={cx('sub-page-container')}>
          <section className={cx('section-view')}>
            {posting && (
              <div className={cx('view-contentBox')}>
                <div
                  className={cx('content-description')}
                  dangerouslySetInnerHTML={{ __html: posting.description }}
                />
                <div className={cx('content-list')}>
                  {posting.lists &&
                    posting.lists.map((item, i) => {
                      return (
                        <div className={cx('item')} key={i}>
                          <p> {item.text}</p>
                          <div
                            className={cx('contents')}
                            dangerouslySetInnerHTML={{ __html: item.content }}></div>
                        </div>
                      );
                    })}
                </div>
                <div
                  className={cx('content-additional')}
                  dangerouslySetInnerHTML={{ __html: posting.additional }}></div>
                <div className={cx('content-welfare')}>
                  <p>네오위즈 복지</p>
                  <Welfare title={'refresh'} className={cx('refresh')} />
                  <Welfare title={'massage'} className={cx('massage')} />
                  <Welfare title={'healthmedical'} className={cx('healthmedical')} />
                  <Welfare title={'cafeteria'} className={cx('cafeteria')} />
                </div>
                <div className={cx('content-more')}>
                  <p>네오위즈 더 알아보기</p>
                  <div className={cx('link')}>
                    <a
                      href="https://www.linkedin.com/company/6889107/admin/"
                      className={cx('btn-link-linkedin')}
                      target="_blank">
                      linkedIn
                    </a>
                    <a
                      href="https://www.instagram.com/neowiz_official/?hl=ko"
                      className={cx('btn-link-instagram')}
                      target="_blank">
                      Intagram
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCPxSQrpLyon5LA_19oYmaDQ"
                      className={cx('btn-link-youtube')}
                      target="_blank">
                      Youtube
                    </a>
                  </div>
                </div>
                {similarPostings.length > 0 && (
                  <div>
                    <p className={cx('sub-title')}>[비슷한 공고 보기]</p>
                    <div className={cx('story-list')}>
                      {similarPostings.map((item, i) => {
                        return (
                          <Link key={i} to={`/careers/recruit?id=${item.id}`}>
                            <RecruitCard
                              post={item}
                              onClick={navigateToPost}
                              className={cx('recruite-card-cij')}
                            />
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className={cx('view-nav')}>
              <div className={cx('list')}>
                <Link to={`/careers/recruit`}>
                  <p>목록보기</p>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </StickyGoTop>
    </>
  );
}
