import imgSearch from '@/assets/images/careers/search.png';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
type Props = {
  content?: string;
  className?: string;
  submitSearch: (search: string) => void;
  redirect?: boolean;
  link?: string
};

export function SearchBar({ submitSearch, redirect = false, link }: Props) {
  const { t } = useTranslation('careers');
  const { search: locationSearch } = useLocation();
  const params = new URLSearchParams(locationSearch);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const handleSearch = (text: string) => {
    if (text.replace(/\s+/g, '') == '') return submitSearch('');
    // redirect가 필요할 경우
    if (redirect && link) {
      navigate(`${link}?searchText=${text}`);
      return;
    }
    submitSearch(text);
  };

  // 뒤로 가기시 url 파마리터 참조해 초기화
  useEffect(() => {
    if (params.has('searchText')) return setSearch(params.get('searchText') || '');
    setSearch('');
    handleSearch('');
  }, [locationSearch]);

  return (
    <div className="search-input-box">
      {/* 로딩 걸어두기 */}
      <input
        type="text"
        placeholder={t('story.search') ?? ''}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(search);
          }
        }}
      />
      <img
        src={imgSearch}
        alt="search"
        onClick={() => {
          handleSearch(search);
        }}
      />
    </div>
  );
}
