import { useTranslation } from 'react-i18next';
import { InvestorsModel } from '@/model/inverstors-model';
import { API_PATH } from '@/constants';
import { useBoard } from '@/hooks/useBoard';
import { PaginationPage } from '@/components/PaginationPage';
import { useInView } from 'react-intersection-observer';
import { TInvestors } from '.';
import { useEffect } from 'react';
type Props = {
  setInview: (viewValue: TInvestors) => void;
};
const Disclosure = ({ setInview }: Props) => {
  const { t } = useTranslation('investors');
  const { ref, inView, entry } = useInView({
    threshold: 1,
  });
  useEffect(() => {
    if (inView) {
      setInview('disclosure');
      return;
    }
  }, [inView]);
  const {
    setCurrentPage,
    swrData: { data: res, error },
  } = useBoard<InvestorsModel.DisclosureRes>(`${API_PATH['{lang}/investors/disclosure']}`);

  return (
    <section className={`section-disclosure`} ref={ref}>
      <div className="bookmark" id={'disclosure'} />
      <h3 className="sub-title">
        {t('disclosure')}
        <a className="btn" href="/policy/inner" target="_blank">
          {t('regulation')}
        </a>
      </h3>
      <table className="pc">
        <thead>
          <tr>
            <td>{t('title')}</td>
            <td>{t('submissionObligator')}</td>
            <td>{t('date')}</td>
          </tr>
        </thead>
        <tbody>
          {res?.data &&
            res.data.map((v, key) => {
              return (
                <tr key={key}>
                  <td>
                    <a
                      className="disclosure-link"
                      target="_blank"
                      href={`https://kind.krx.co.kr/common/disclsviewer.do?method=search&acptno=${v.acptNo}`}>
                      {v.subject}
                    </a>
                  </td>
                  <td>{v.obligator}</td>
                  <td>{v.date.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className={'mob'}>
        <div className="thead">
          <p>{`${t('title')} / ${t('submissionObligator')} / ${t('date')}`}</p>
        </div>
        {res?.data &&
          res?.data.map((v, key) => {
            return (
              <div className="item-mob" key={key}>
                <a
                  className="disclosure-link"
                  target="_blank"
                  href={`https://kind.krx.co.kr/common/disclsviewer.do?method=search&acptno=${v.acptNo}`}>
                  <p className="subject">{v.subject}</p>
                  <p className="obligator"> {v.obligator}</p>
                  <p className="date">
                    {v.date.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}
                  </p>
                </a>
              </div>
            );
          })}
      </div>
      {res && (
        <PaginationPage
          setCurrentPage={setCurrentPage}
          totalPage={res.totalPage}
          currentPage={res.page}
        />
      )}
    </section>
  );
};

export default Disclosure;
