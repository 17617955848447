import { NoticePopupModel } from '@/model/notice-popup-model';
import { getCookie, setCookie } from '@/util/cookies';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  style: CSSProperties;
  noticePopup: NoticePopupModel.Popup;
};
export function NoticeModal({ noticePopup, style }: Props) {
  const { title, subtitle, contents, url } = noticePopup;
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('etc');
  useEffect(() => {
    const cookie = getCookie(`popup-${title}`);
    if (cookie) return;
    setVisible(true);
  }, []);

  return visible ? (
    <section className="modal">
      <div className="modal-container" style={style}>
        <div className="modal-body">
          {title && <h1>{title}</h1>}
          {subtitle && (
            <div className="modal-sub-title" dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
          {contents && (
            <div className="modal-desc" dangerouslySetInnerHTML={{ __html: contents }} />
          )}
          {url && (
            <a href={url} className="btn-link">
              {t('bt.detail')}
            </a>
          )}
        </div>

        <div className="modal-footer">
          <a
            onClick={() => {
              setCookie(`popup-${title}`, 'true');
              setVisible(false);
            }}
            className="btn-not-today">
            {t('bt.not-today')}
          </a>
          <a onClick={() => setVisible(false)}>{t('bt.close')}</a>
        </div>
      </div>
    </section>
  ) : null;
}
