import about1 from '@/assets/images/about/about1.png';
import about2 from '@/assets/images/about/about2.png';
import about3 from '@/assets/images/about/about3.png';

import { Trans, useTranslation } from 'react-i18next';

const AboutWeAre = () => {
  const { t } = useTranslation('about');
  return (
    <section className={`section-we`}>
      <p className="section-title">WE ARE</p>
      <img src={about1} alt="weare" className="about1" />
      <p className="main-content">
        <Trans t={t} i18nKey={'about.content'} />
      </p>
      <div className="content1">
        <img src={about2} alt="weare1" className="about2" />
        <p className="content-text">
          <Trans t={t} i18nKey={'about.content1'} />
        </p>
      </div>
      <div className="content2">
        <p className="content-text">
          <Trans t={t} i18nKey={'about.content2'} />
        </p>
        <img src={about3} alt="weare2" className="about3" />
      </div>
    </section>
  );
};

export default AboutWeAre;
