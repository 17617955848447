import { useEffect, useState } from 'react';

import { useMatchMedia } from '@/hooks/useMatchMedia';
import { LinkPc } from './LinksPc';
import { LinkMobile } from './LinkMobile';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ILinkInfo, ISiteMap, siteMap } from '@/siteMap';
import { useTranslation } from 'react-i18next';
import { DISABLE_EN_ROUTE_LIST } from '@/constants';

export const MainNav = () => {
  const [filteredSiteMap, setSiteMap] = useState<ISiteMap>(siteMap);
  const { VIEW_PC } = useMatchMedia();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();

  const headStyle = () => {
    const navTransparent = () => {
      document.body.classList.remove('nav-top--false');
      document.body.classList.add('nav-top--true');
    };

    const navUnTransparent = () => {
      document.body.classList.remove('nav-top--true');
      document.body.classList.add('nav-top--false');
    };

    if (pathname !== '/') return navUnTransparent();

    if (100 > window.scrollY) {
      navTransparent();
    } else {
      navUnTransparent();
    }
  };

  useEffect(() => {
    headStyle();
    window.addEventListener('scroll', headStyle, { passive: true });
    return () => {
      window.removeEventListener('scroll', headStyle);
      document.body.classList.remove('nav-top--true', 'nav-top--false');
    };
  }, []);

  useEffect(() => {
    if (i18n.language !== 'en') return setSiteMap(siteMap);

    function langFilter(item: ILinkInfo) {
      let isFiltering = true;
      for (let i = 0; i < DISABLE_EN_ROUTE_LIST.length; i++) {
        if (!isFiltering) break;
        isFiltering = item.path.indexOf(DISABLE_EN_ROUTE_LIST[i]) < 0;
      }

      return isFiltering;
    }
    setSiteMap(
      siteMap
        .filter(langFilter)
        .map((item) =>
          item.subMenu ? { ...item, subMenu: item.subMenu.filter(langFilter) } : item
        )
    );
  }, [i18n.language]);

  return (
    <section className="nav-holder">
      <nav className={`nav`}>
        <Link to="/" className="nav-ci">
          NEOWIZ
        </Link>
        {VIEW_PC && <LinkPc siteMap={filteredSiteMap} />}
        {!VIEW_PC && <LinkMobile siteMap={filteredSiteMap} />}
      </nav>
    </section>
  );
};
