import { HomeSlider } from './HomeSlider';
import { MainNav } from '../../components/MainNav';
import { HomeAbout } from './HomeAbout';
import { HomeGames } from './HomeGames';
import { HomeNews } from './HomeNews';
import { HomeCareers } from './HomeCareers';
import { HomeContactUs } from './HomeContactUs';
import { AppFooter } from '@/components/AppFooter';
import { StickyGoTop } from '@/components/StickyGoTop';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
  return (
    <div className="page-home">
      <Helmet>
        <title>네오위즈 - 네오위즈, 세상이 즐거워지는 마법</title>
      </Helmet>
      <MainNav />
      <HomeSlider />
      <HomeAbout />
      <HomeGames />
      <StickyGoTop>
        <HomeNews />
        <HomeCareers />
        <HomeContactUs />
      </StickyGoTop>
      <AppFooter />
    </div>
  );
};

export default HomePage;
