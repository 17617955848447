import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import './lib/i18n';
import { Prelude } from './Prelude';
import './styles/main.scss';
import './util/matchMedia';

const gaTrackingCode = import.meta.env.VITE_GA_ID;

if (!gaTrackingCode) {
  throw new Error('GA Tracking code required!');
}

ReactGA.initialize(gaTrackingCode);

const container = document.getElementById('root');

if (!container) {
  throw new Error('#root NOT found!');
}

const root = createRoot(container);

root.render(
  <StrictMode>
    <Prelude />
  </StrictMode>
);
