import { ROUTES } from '@/constants';
import { useMatchMedia } from '@/hooks/useMatchMedia';
import { HomeModel } from '@/model/home-model';
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { Options } from 'youtube-player/dist/types';

type SlideProps = {
  banner: HomeModel.Banner;
  slideData: {
    isActive: boolean;
    isVisible: boolean;
    isDuplicate: boolean;
    isPrev: boolean;
    isNext: boolean;
  };
  active?: boolean;
};

export function HomeSlide({ banner, slideData }: SlideProps) {
  const [ytReady, setYtReady] = useState(false);
  const ytRef = useRef<YouTube>(null);
  const { VIEW_MOBILE } = useMatchMedia();
  const { i18n } = useTranslation();

  const getYtPlayer = () => {
    if (ytRef && ytRef.current) {
      return ytRef.current.getInternalPlayer();
    }
    return null;
  };

  useLayoutEffect(() => {
    if (!slideData && !banner.youtube) return;
    setYtReady(() => false);
    return () => {
      const player = getYtPlayer();
      if (player) player.destroy();
    };
  }, [slideData]);

  return (
    <div className={`slide-content yt-ready--${ytReady}`}>
      {slideData.isActive && banner.youtube && (
        <>
          <div className="pattern" />
          <YouTube
            iframeClassName="yt-iframe"
            ref={ytRef}
            className="yt-holder"
            {...ytOptions(banner.youtube)}
            onReady={(e) => {
              const player = e.target;
              VIEW_MOBILE && player.setPlaybackQuality('144p');
              player.playVideo();
            }}
            onStateChange={(e) => {
              if (e.data === 1) {
                setYtReady(() => true);
              } else {
                setYtReady(() => false);
              }
            }}
          />
        </>
      )}
      <img src={VIEW_MOBILE ? banner.bgMobileUrl : banner.bgPcUrl} className="bg" />
      <section className="slide-body">
        <img src={banner.brandImgUrl} className={'brand-img'} />
        {banner.title && <h3>{banner.title}</h3>}
        <div className={'btns'}>
          {banner.gameLink && (
            <a href={banner.gameLink} className="btn-banner">
              <span>GAMES</span>
              <i />
            </a>
          )}
          {banner.jobLink && (
            <Link
              className="btn-banner"
              to={ROUTES['/careers']}
              onClick={() => {
                i18n.changeLanguage('ko');
              }}>
              <span>CAREERS</span>
              <i />
            </Link>
          )}
        </div>
      </section>
    </div>
  );
}

function ytOptions(videoId: string): { videoId: string; opts: Options } {
  return {
    videoId,
    opts: {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        controls: 0,
        disablekb: 1,
        enablejsapi: 1,
        loop: 1,
        // @ts-ignore
        mute: 1, // ISSUE: https://github.com/tjallingt/react-youtube/issues/327 - 자동 재생이 잘안되는데 지원하지 않는 파라미터 추가 하면 된다...
        rel: 0,
        playsinline: 1,
        playlist: videoId,
        origin: location.origin,
      },
    },
  };
}

//https://www.youtube.com/embed/Pql-BlnYa0E?autoplay=1&controls=0&disablekb=1&enablejsapi=1&loop=1&rel=0&playsinline=1&playlist=Pql-BlnYa0E&origin=http%3A%2F%2Flocalhost%3A5173&widgetid=11
