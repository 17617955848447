import pdf from '@/assets/images/investors/pdf.png';
import xls from '@/assets/images/investors/xls.png';
import download from '@/assets/images/investors/download.png';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { InvestorsModel } from '@/model/inverstors-model';
import { useInView } from 'react-intersection-observer';
import { TInvestors } from '.';
type Props = {
  data: InvestorsModel.earningsRelease | undefined;
  setInview: (viewValue: TInvestors) => void;
};
const Earnings = ({ data, setInview }: Props) => {
  const { t } = useTranslation('investors');
  const [selectedYear, setSelectedYear] = useState('');
  const [onYearList, setOnYearList] = useState('off');
  const { ref, inView, entry } = useInView({
    threshold: 0.6,
  });
  useEffect(() => {
    if (inView) {
      setInview('earnings');
      return;
    }
  }, [inView]);
  const handleYearList = () => {
    if (onYearList === 'on') {
      setOnYearList('off');
    } else {
      setOnYearList('on');
    }
  };
  useEffect(() => {
    if (!data) return;
    setSelectedYear(data.years[0]);
  }, [data]);
  return (
    <section className={`section-earnings`} ref={ref}>
      <div className="bookmark" id={'earningsrelease'} />
      <h3 className="sub-title">{t('earnings')}</h3>
      <div className="earnings-info">
        <a
          className="year"
          onClick={() => {
            handleYearList();
          }}>
          {`${selectedYear}`}
          <span className={`year-list-btn`}>
            <i className={`bottom-arrow ${onYearList}`} />
          </span>
        </a>

        <div className={`year-list-box ${onYearList}`}>
          <div className="year-list">
            {data?.years &&
              data.years.map((v, key) => {
                return (
                  <ul key={key} className="year-list-item">
                    <li
                      key={v}
                      className={`${v === selectedYear && 'active'}`}
                      onClick={() => {
                        handleYearList();
                        setSelectedYear(v);
                      }}>{`${v}`}</li>
                  </ul>
                );
              })}
          </div>
        </div>
      </div>
      <div className="earnings-table">
        <div className="tableBox">
          {data?.items[selectedYear] &&
            data.items[selectedYear].map((v, key) => {
              return (
                <div key={key} className="table-box-item">
                  <div className="box-header">
                    <p>{v.title}</p>
                  </div>
                  <div className="box-body">
                    {v.pdfFileUrl && (
                      <a href={v.pdfFileUrl} target="_blank" className="result-data">
                        <em>
                          <span>{t('presentation')}</span>
                          <img className="icon" src={pdf} alt="" />
                        </em>
                        <img className="download" src={download} alt="" />
                      </a>
                    )}
                    {v.xlsFileUrl && (
                      <a href={v.xlsFileUrl} target="_blank" className="result-data sheet">
                        <em>
                          <span>{t('factsheet')}</span>
                          <img className="icon" src={xls} alt="" />
                        </em>

                        <img className="download" src={download} alt="" />
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Earnings;
