import { Component, PropsWithChildren } from 'react';

interface State {
  errorName: string;
  errorMessage: string;
  hasError: boolean;
}

export class ErrorBoundary extends Component<PropsWithChildren<{}>, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorName: '', errorMessage: '' };
  }

  static getDerivedStateFromError(error: unknown) {
    const isError = error instanceof Error;

    return {
      hasError: true,
      errorName: isError ? error.name : 'Unknown Error',
      errorMessage: isError ? error.message : error ?? 'Not set',
    };
  }

  componentDidCatch(error: unknown, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="page-not-found">
          <section className="container">
            <h4 style={{ fontSize: 80 }}>Oops! Sorry...</h4>
            <p className="sub-title">{this.state.errorName}</p>
            <p className="info">{this.state.errorMessage}</p>
            <div>Please try again later.</div>
          </section>
        </div>
      );
    }

    return this.props.children;
  }
}
