import { RecruitModel } from '@/model/recruit-model';
import styles from './CategoryButtonGroup.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type ButtonGroupProps = {
  category: RecruitModel.CategoryButtonType;
  // list: string[] | undefined;
  list: any;
  selected: string[];
  handleSelect: (categoryType: RecruitModel.CategoryButtonType, selectedName: string) => void;
  className: string;
};

export const CategoryButtonGroup: React.FC<ButtonGroupProps> = ({
  category,
  list,
  selected,
  handleSelect,
  className,
}) => (
  <div className={`${cx('category-button-group')} ${cx(className)}`}>
    {list?.map((obj: any, index: any) => (
      <label key={index}>
        <input
          type="checkbox"
          name={category}
          value={obj.name}
          checked={selected.includes(obj.name)}
          onChange={() => handleSelect(category, obj.name)}
        />
        <span>{obj.name}</span>
        {(category === 'team' || category === 'department') && (
          <span className={cx('count')}> ({obj.count})</span>
        )}
      </label>
    ))}
  </div>
);
