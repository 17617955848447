import { RecruitModel } from '../model/recruit-model';

export function organizeLeverCategories(
  params: RecruitModel.IPostingRes[]
): RecruitModel.CategoryList {
  const sortedPostings: RecruitModel.IPostingRes[] = params.sort(
    (a, b) => b.createdAt - a.createdAt
  );

  let commitmentSet: Set<string> = new Set();
  let departmentSet: Set<string> = new Set();
  let locationSet: Set<string> = new Set();
  let teamSet: Set<string> = new Set();

  for (let posting of sortedPostings) {
    const { commitment, location, department, team } = posting.categories;

    //Set 객체는 자료형에 관계 없이 원시 값과 객체 참조 모두 유일한 값을 저장할 수 있다.
    commitmentSet.add(commitment);
    locationSet.add(location);
    // department 값이 "Other teams"이 아니면 추가
    if (department && department !== 'Other teams') {
      departmentSet.add(department);
    }
    teamSet.add(team);
  }

  // Convert back to arrays
  let commitmentList = Array.from(commitmentSet);
  let departmentList = Array.from(departmentSet);
  let locationList = Array.from(locationSet);
  let teamList = Array.from(teamSet);

  return { commitmentList, departmentList, locationList, teamList };
}

function sortByName(list: { name: string; count: number }[], reverse: boolean = false) {
  if (reverse) {
    return list.sort((a, b) => b.name.localeCompare(a.name));
  } else {
    return list.sort((a, b) => a.name.localeCompare(b.name));
  }
}

export function organizeAndCountLeverCategories(
  params: RecruitModel.IPostingRes[]
): RecruitModel.CategoryCountMap {
  let commitmentMap: Map<string, number> = new Map();
  let departmentMap: Map<string, number> = new Map();
  let locationMap: Map<string, number> = new Map();
  let teamMap: Map<string, number> = new Map();

  for (let posting of params) {
    const { commitment, location, department, team } = posting.categories;

    commitmentMap.set(commitment, (commitmentMap.get(commitment) || 0) + 1);
    locationMap.set(location, (locationMap.get(location) || 0) + 1);
    // department 값이 "Other teams"이 아니면 추가
    if (department && department !== 'Other teams') {
      departmentMap.set(department, (departmentMap.get(department) || 0) + 1);
    }
    teamMap.set(team, (teamMap.get(team) || 0) + 1);
  }

  return {
    commitmentList: sortByName(
      Array.from(commitmentMap).map(([name, count]) => ({
        name,
        count,
      })),
      true
    ),
    departmentList: sortByName(
      Array.from(departmentMap).map(([name, count]) => ({
        name,
        count,
      }))
    ),
    locationList: sortByName(
      Array.from(locationMap).map(([name, count]) => ({
        name,
        count,
      }))
    ),
    teamList: sortByName(
      Array.from(teamMap).map(([name, count]) => ({
        name,
        count,
      }))
    ),
  };
}
