import i18n from '@/lib/i18n';

type Props = {
  message?: string;
};

export function NoResult({ message = i18n.t('no result') ?? 'No result' }: Props) {
  return (
    <div className={`no-result`}>
      <i className="icon-no-result" />
      <p>{message}</p>
    </div>
  );
}
