import { Trans, useTranslation } from 'react-i18next';
import { Control, Controller, useForm } from 'react-hook-form';
import PartnershipInputHolder from './PartnershipInputHolder';
import { ContactUsModel } from '@/model/contactus-model';
import { useCallback, useState, useEffect } from 'react';
import { fetcher } from '@/service/base-api';
import { API_PATH } from '@/constants';
import { StickyGoTop } from '@/components/StickyGoTop';

interface IPartnershipFormValues
  extends Omit<ContactUsModel.PartnerShipForm, 'platform' | 'requestContent' | 'type'> {
  platform?: typeof platforms[number][];
  requestContent?: typeof requests[number][];
}

const platforms = ['mobile', 'PC', 'Console', 'VR', 'AR', 'All', 'etc', 'none'] as const;
const requests = ['publishing', 'investment', 'affiliate', 'All', 'etc'] as const;

const Partnership = () => {
  const { t } = useTranslation('contact-us');
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    getValues,
    setError,
    clearErrors,
    trigger,
    watch,
    control,
  } = useForm<IPartnershipFormValues>({
    mode: 'onChange',
  });

  const multipleCheckboxCustomError = () => {
    const arrayJoin = (v?: any[]) => (v ? v.filter(Boolean).join(',') : '');
    const formData = getValues();
    const platform = arrayJoin(formData?.platform);
    const requestContent = arrayJoin(formData?.requestContent);
    const errorCase = (v: boolean, key: keyof IPartnershipFormValues) =>
      v
        ? setError(key, {
            type: 'manual',
            message: 'required',
          })
        : clearErrors(key);

    errorCase(!platform, 'platform');
    errorCase(!requestContent, 'requestContent');
    return {
      formData,
      platform,
      requestContent,
    };
  };

  useEffect(() => {
    const subscription = watch(() => multipleCheckboxCustomError());
    return () => subscription.unsubscribe();
  }, [watch]);

  const submitForm = useCallback(async () => {
    try {
      if (!(await trigger(undefined, { shouldFocus: true }))) return;
      setLoading(true);
      const { formData, platform, requestContent } = multipleCheckboxCustomError();
      const data = {
        ...formData,
        type: 'Neowiz official - 제휴 문의',
        platform,
        requestContent,
      };
      fetcher<null, ContactUsModel.PartnerShipForm>(API_PATH.contact, data, { method: 'POST' })
        .then(() => {
          alert('Email Send Success');
        })
        .catch(() => {
          alert('Email Send Fail');
        })
        .finally(() => {
          setLoading(false);
          reset();
        });
    } catch (error) {
      throw error;
    }
  }, [loading]);

  return (
    <div>
      <p className="section-title">{t('inquiries')}</p>
      <StickyGoTop>
        <div className="partnership-form">
          <p className="necessary">{t('required')}</p>
          <form autoComplete={'off'}>
            <PartnershipInputHolder hasError={!!errors?.name} label={t('name')} necessary>
              <input
                className="input-text"
                type="text"
                {...register('name', { required: true, maxLength: 64 })}
                placeholder={t('placeholder.name') ?? ''}
              />
            </PartnershipInputHolder>

            <PartnershipInputHolder hasError={!!errors?.email} label={t('email')} necessary>
              <input
                className="input-text"
                type="email"
                {...register('email', {
                  required: true,
                  maxLength: 128,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                })}
                placeholder={'example@neowiz.com'}
              />
            </PartnershipInputHolder>

            <PartnershipInputHolder hasError={!!errors?.company} label={t('company')} necessary>
              <input
                className="input-text"
                type="text"
                {...register('company', { required: true, maxLength: 128 })}
                placeholder={t('placeholder.company') ?? ''}
              />
            </PartnershipInputHolder>
            <PartnershipInputHolder label={t('country')}>
              <input
                className="input-text"
                type="text"
                {...register('country', { maxLength: 64 })}
                placeholder={t('placeholder.country') ?? ''}
              />
            </PartnershipInputHolder>

            <PartnershipInputHolder label={t('website')}>
              <input
                className="input-text"
                type="text"
                {...register('webSite', { maxLength: 255 })}
                placeholder={'https://www.example.com'}
              />
            </PartnershipInputHolder>
            <PartnershipInputHolder hasError={!!errors?.gameName} label={t('gamename')} necessary>
              <input
                className="input-text"
                type="text"
                {...register('gameName', { required: true, maxLength: 255 })}
                placeholder={t('placeholder.gamename') ?? ''}
              />
            </PartnershipInputHolder>
            <PartnershipInputHolder hasError={!!errors?.platform} label={t('platform')} necessary>
              <div className={`multiple-checkbox-holder`}>
                {platforms.map((value, i) => (
                  <InputCheckbox
                    key={i}
                    name={`platform[${i}]` as keyof IPartnershipFormValues}
                    control={control}
                    value={value}
                  />
                ))}
              </div>
            </PartnershipInputHolder>
            <PartnershipInputHolder
              hasError={!!errors?.requestContent}
              label={t('request')}
              necessary>
              <div className={`multiple-checkbox-holder`}>
                {requests.map((value, i) => (
                  <InputCheckbox
                    key={i}
                    name={`requestContent[${i}]` as keyof IPartnershipFormValues}
                    control={control}
                    value={value}
                  />
                ))}
              </div>
            </PartnershipInputHolder>
            <PartnershipInputHolder
              hasError={!!errors?.requestDescription}
              label={t('explanation')}
              necessary>
              <textarea
                className="input-textarea"
                {...register('requestDescription', { required: true, maxLength: 65530 })}
                placeholder={t('placeholder.textarea') ?? ''}
              />
            </PartnershipInputHolder>
            {['www.dq.neowiz.com', 'localhost:3000'].includes(location.host) && (
              <PartnershipInputHolder
                hasError={!!errors?.testSendEmail}
                label={'dq.neowiz.com 에서만 나옵니다 / 사업제휴 폼을 받을 이메일을 입력하세요'}
                necessary>
                <input
                  className="input-text"
                  type="text"
                  {...register('testSendEmail', { maxLength: 64 })}
                  placeholder={'테스트 해볼 Email을 넣으세요'}
                />
              </PartnershipInputHolder>
            )}
            <div className={`agree has-error--${!!errors?.agree}`}>
              <p className="agree-title">{t('agree')}</p>
              <div className={`agree-body`}>
                <label className="label-checkbox">
                  <Controller
                    name="agree"
                    control={control}
                    rules={{
                      validate: {
                        agree: (v) => v === 'Y',
                      },
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        ref={field.ref}
                        type="checkbox"
                        onChange={(e) => field.onChange(e.target.checked ? 'Y' : 'N')}
                        checked={field.value === 'Y'}
                      />
                    )}
                  />
                  <i className="marker" />
                </label>
                <p>
                  <Trans className="agree-text" t={t} i18nKey={'agree.text1'} />
                </p>
              </div>
            </div>

            <div className="apply">
              <a className="apply-button" onClick={() => submitForm()}>
                <span>{t('btn.submit')}</span>
                <i />
              </a>
            </div>
          </form>
        </div>
      </StickyGoTop>
    </div>
  );
};

type CProps = {
  control: Control<IPartnershipFormValues>;
  value: string;
  name: keyof IPartnershipFormValues;
};
const InputCheckbox = (props: CProps) => {
  const { t } = useTranslation('contact-us');
  return (
    <Controller
      {...props}
      render={({ field }) => {
        return (
          <label className="label-checkbox">
            <input
              {...field}
              ref={field.ref}
              type="checkbox"
              value={props.value}
              checked={field.value === props.value}
              onChange={(event) => {
                field.onChange(event.target.checked ? props.value : undefined);
              }}
            />
            <span>{t(props.value)}</span>
            <i className="marker" />
          </label>
        );
      }}
    />
  );
};

export default Partnership;
