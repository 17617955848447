import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  img: string | undefined;
  contents: string;
  date?: string;
  writer?: string;
  className?: string;
};

const ContentListItem = ({
  title,
  img,
  contents,
  date,
  writer,
  className: propsClassName = '',
}: Props) => {
  const hasDateClassName = Boolean(date) ? 'has-date' : '';

  return (
    <div className={`story-list-item ${propsClassName}`}>
      <div className="img-box img">{img && <img src={img} alt="" />}</div>
      <div className="contentBox">
        <p className={`title ${hasDateClassName}`}>{title}</p>
        <div className="contents" dangerouslySetInnerHTML={{ __html: contents }} />
        {writer && <p className="writer">{`[${writer}]`}</p>}
        {date && <p className="date">{date}</p>}
      </div>
    </div>
  );
};

export default ContentListItem;
