import pdf from '@/assets/images/investors/pdf.png';
import download from '@/assets/images/investors/download.png';
import { useTranslation } from 'react-i18next';
import { InvestorsModel } from '@/model/inverstors-model';
import { PaginationPage } from '@/components/PaginationPage';
import { useBoard } from '@/hooks/useBoard';
import { API_PATH } from '@/constants';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { TInvestors } from '.';
import { useEffect } from 'react';
type Props = {
  setInview: (viewValue: TInvestors) => void;
};
const Announcement = ({ setInview }: Props) => {
  const { t } = useTranslation('investors');
  const {
    setCurrentPage,
    swrData: { data: res, error },
  } = useBoard<InvestorsModel.AnnouncementListRes>(`${API_PATH['{lang}/investors/announcement']}`);

  const { ref, inView } = useInView({
    threshold: 0.7,
  });

  useEffect(() => {
    if (inView) {
      setInview('announcement');
      return;
    }
  }, [inView]);

  return (
    <section className={`section-announcement`} ref={ref}>
      <div className="bookmark" id={'announce'} />
      <h3 className="sub-title">{t('announcement')}</h3>
      <table className="pc">
        <thead>
          <tr>
            <td>{t('title')}</td>
            <td>{t('materials')}</td>
            <td>{t('date')}</td>
          </tr>
        </thead>
        <tbody>
          {res?.data &&
            res.data.map((v, key) => {
              return (
                <tr key={key}>
                  <td>
                    <Link className="announce-link" to={`/investors/announcement/${v.seq}`}>
                      {v.title}{' '}
                    </Link>
                  </td>
                  <td>
                    {v.pdfFileUrl && (
                      <a className="materials" href={v.pdfFileUrl} target="_blank">
                        <img className="icon" src={pdf} alt={''} />
                        <img src={download} alt="" />
                      </a>
                    )}
                  </td>
                  <td>{v.regDate.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className={'mob'}>
        <div className="thead">
          <p>{`${t('title')} / ${t('materials')} / ${t('date')}`}</p>
        </div>
        {res?.data &&
          res.data.map((v, key) => {
            return (
              <div className="item-mob" key={key}>
                <Link className="subject" to={`/investors/announcement/${v.seq}`}>
                  {v.title}
                </Link>
                {v.pdfFileUrl && (
                  <a className="link materials" href={v.pdfFileUrl} target="_blank">
                    <img className="icon" src={pdf} alt={''} />
                    <img src={download} alt="" />
                  </a>
                )}

                <p className="date">
                  {v.regDate.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}
                </p>
              </div>
            );
          })}
      </div>
      {res && (
        <PaginationPage
          setCurrentPage={setCurrentPage}
          totalPage={res.totalPage}
          currentPage={res.page}
        />
      )}
    </section>
  );
};

export default Announcement;
