import { NewsModel } from '@/model/news-model';
import { Link } from 'react-router-dom';

type Props = {
  data: NewsModel.NewsList[];
};
export const TopNews = ({ data }: Props) => {
  return (
    <div className="news-banners">
      {data &&
        data.map((item, i) => {
          return (
            <div className="news-banner" key={i}>
              <Link to={`/media/press/${item.seq}`}>
                <div className="banner-item">
                  <div className="img-box img">
                    {item.imageTopUrl && (
                      <img className="background-img" src={item.imageTopUrl} alt="" />
                    )}
                  </div>
                  <div className="gradient-img"></div>
                  {/* <img className="gradient-img" src={gradient} alt="" /> */}
                  <div className="banner-contents">
                    <div className="title">
                      <p>{item.subject}</p>
                    </div>
                    {/* <p className="title"></p> */}
                    <p className="date">
                      {item.regDate.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </div>
  );
};
