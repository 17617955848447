import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {
  const { pathname, hash, search } = useLocation();

  useEffect(() => {
    if (hash) return;
    if (pathname === '/careers') return;
    window.scrollTo(0, 0);
  }, [pathname, hash, search]);

  return null;
}
