import { useEffect } from 'react';
/**
 *
 * .suspense-fallback 돔을 미리 index.html에 생성해두고
 * body css transition을 이용해 부드러운 suspense spinner를 구현한다.
 */
export function Spinner() {
  useEffect(() => {
    document.body.classList.add('suspense--pending');
    return () => {
      document.body.classList.remove('suspense--pending');
    };
  }, []);
  return null;
}
