import { useState } from 'react';
import styles from './FaqCard.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  title: string;
  type: string;
  content: string;
  isContentVisible: boolean;
  setIsContentVisible: () => void;
};

const FaqCard = ({ title, content, type, isContentVisible, setIsContentVisible }: Props) => {
  const handleTitleClick = () => {
    setIsContentVisible();
  };

  return (
    <div className={cx('faq-item')}>
      <p className={cx('title', { visible: isContentVisible })} onClick={handleTitleClick}>
        <span className={cx('question-prefix')}>Q. </span>
        <span className={cx('question')}>{title}</span>
        <i className={cx('arrow', { up: isContentVisible, down: !isContentVisible })}></i>
      </p>
      <p className={cx('content', { hidden: !isContentVisible })}>
        <span className={cx('answer-prefix')}>A. </span>
        <span className={cx('answer')}>{content}</span>
      </p>
    </div>
  );
};

export default FaqCard;
