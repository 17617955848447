import { fetcher } from '@/service/base-api';
import { supplant } from '@/util/string';
import { useTranslation } from 'react-i18next';
import { CareersModel } from '@/model/careers-model';
import useSWR from 'swr';

// category에 매칭되는 목록만 filtering 하고, hashtag에 입력된 숫자를 기준으로 오름차순 정렬을 한다.
// hashtag로 정렬을 하는 이유는 어드민툴의 inside API가 seq를 return하지 않기 때문이다.
function selectCategory(req: CareersModel.WelfareRes, category: CareersModel.WelfareCategory) {
  return {
    ...req,
    data: req.data
      .filter((item) => item.category === category)
      .sort((a, b) => parseInt(a.hashtag) - parseInt(b.hashtag)),
  };
}

export function useInsideAPI(path: string, category: CareersModel.WelfareCategory) {
  const { i18n } = useTranslation();
  // lang 표준과 서버 lang path가 달라 변환
  const koToKr = (s: string) => (s === 'ko' ? 'kr' : s);

  const { data, error } = useSWR(supplant(path, { lang: koToKr(i18n.language) }), (url) =>
    fetcher<CareersModel.WelfareRes>(url)
  );

  return data ? selectCategory(data, category) : undefined;
}
