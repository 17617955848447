import { useState } from 'react';

import { LangChanger } from './LangChanger';
import { ISiteMap } from '@/siteMap';
import { toggleSiblingsClass } from '@/util/dom';
import { SubLink } from './SubLink';
import { NavLink } from 'react-router-dom';

type Props = {
  siteMap: ISiteMap;
};
export const LinkMobile = ({ siteMap }: Props) => {
  const [mobileMenuOpen, setMobileMenu] = useState(false);

  const toggleMobileMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (mobileMenuOpen) {
      setMobileMenu(false);
      document.body.classList.remove('body-scroll-lock', 'nav--open');
    } else {
      setMobileMenu(true);
      document.body.classList.add('body-scroll-lock', 'nav--open');
    }
  };

  const openSubMenu = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const OPEN_CLASS = 'sub-open';
    toggleSiblingsClass(e, OPEN_CLASS);
  };

  return (
    <>
      <a className={'btn-hamburger'} onClick={toggleMobileMenu}>
        열기
        <span className="ham-line ham-line1" />
        <span className="ham-line ham-line2" />
        <span className="ham-line ham-line3" />
      </a>
      <div className="links-mobile">
        <div className="scroll">
          <ul className={`main-link`}>
            {siteMap.map(({ name, path, subMenu }, mainIdx) => {
              const hasSub = subMenu && subMenu.length > 0;
              return (
                <li
                  key={mainIdx}
                  data-el-index={mainIdx}
                  onClick={!!subMenu ? openSubMenu : undefined}>
                  {hasSub && (
                    <a href={undefined}>
                      <span>{name}</span>
                    </a>
                  )}
                  {!hasSub && (
                    <NavLink to={path} className={'no-sub-menu'} onClick={toggleMobileMenu}>
                      <span>{name}</span>
                    </NavLink>
                  )}

                  {hasSub && (
                    <ul className="sub-link">
                      {subMenu.map((sub, subIdx) => (
                        <li key={subIdx} onClick={toggleMobileMenu}>
                          <SubLink link={sub} />
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        <LangChanger />
      </div>
    </>
  );
};
