import { ROUTES } from '@/constants';
import { siteMap } from '@/siteMap';
import { useTranslation, Trans } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

export function HomeCareers() {
  const { t } = useTranslation('home');
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const { i18n } = useTranslation();
  return (
    <section className={`section-careers in-view--${inView}`}>
      <div className="slogan" ref={ref}>
        <h3>CAREERS</h3>
        <p>
          <Trans t={t} i18nKey={'careers.text1'} />
        </p>

        <Link
          to={ROUTES['/careers']}
          className="btn-more"
          onClick={() => {
            i18n.changeLanguage('ko');
          }}>
          <span>{t('bt.apply')}</span>
          <i></i>
        </Link>
      </div>
    </section>
  );
}
