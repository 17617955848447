import arrow from '@/assets/images/careers/icon-arrow.png';
import { useLeverAPI } from '@/hooks/useLeverAPI';
import { RecruitModel } from '@/model/recruit-model';
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { NoResult } from '../../components/NoResult';
import { API_PATH, LINKS } from '../../constants';
import { CategoryButtonGroup } from './CategoryButtonGroup';
import { LocationDropDown } from './LocationDropDown';
import { RecruitCard } from './RecruitCard';
import { Spinner } from '@/components/Spinner';
import { RecruitDropDownBox } from './RecruitDropDownBox';
import styles from './RecruitList.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export function RecruitList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
  const [isError, setIsError] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState<RecruitModel.CategoryState>({
    commitment: [],
    department: [],
    team: [],
  });
  const [selectedLocation, setSelectedLocation] = useState('all');

  const { categoryList, totalPosting, totalPostingCount, error } = useLeverAPI(API_PATH['lever']);
  const { commitmentList, departmentList, locationList, teamList } = categoryList || {};

  useEffect(() => {
    if (error) {
      setIsError(true);
      setIsLoading(false);
    } else if (categoryList && totalPostingCount) {
      // Loading이 끝나고, categoryList와 totalPosting가 존재할 때만 Loading을 false로 변경
      setIsLoading(false);
    }
  }, [categoryList, totalPosting, error]);

  // categoryType의 타입가드
  function isCategoryButtonType(key: string): key is RecruitModel.CategoryButtonType {
    return key === 'commitment' || key === 'department' || key === 'team';
  }

  // 체크박스 선택에 따른 State & URL 관리
  const handleSelect = (categoryType: RecruitModel.CategoryButtonType, selectedName: string) => {
    // 선택된 항목을 selectedCategories 배열에 담는 기능을 한다
    const newSelectedCategories = {
      ...selectedCategories,
      [categoryType]: selectedCategories[categoryType].includes(selectedName)
        ? selectedCategories[categoryType].filter((i) => i !== selectedName)
        : [...selectedCategories[categoryType], selectedName],
    };
    setSelectedCategories(newSelectedCategories);

    // 선택된 항목을 쉼표로 구분하여 URL 업데이트
    const newSearchParams: Partial<{ [K in RecruitModel.CategoryButtonType]: string }> = {};
    for (const [categoryType, selectedNames] of Object.entries(newSelectedCategories)) {
      if (isCategoryButtonType(categoryType) && selectedNames.length > 0) {
        newSearchParams[categoryType] = selectedNames.join(',');
      }
    }
    setSearchParams(newSearchParams);
  };

  // 필터링 함수. selectedCategories, selectedLocation의 상태값을 기반으로 필터링된 포스팅을 반환
  const filterPostsByCategories = (
    posts: RecruitModel.IPostingRes[],
    categories: RecruitModel.CategoryState,
    location: string
  ) => {
    return posts.filter(
      (post) =>
        (categories.commitment.length === 0 ||
          categories.commitment.includes(post.categories.commitment)) &&
        (categories.department.length === 0 ||
          categories.department.includes(post.categories.department)) &&
        (categories.team.length === 0 || categories.team.includes(post.categories.team)) &&
        (location === 'all' || post.categories.location === location)
    );
  };

  // 필터링 함수의 결과값
  const filteredPosts = filterPostsByCategories(
    totalPosting || [],
    selectedCategories,
    selectedLocation
  );

  // 드랍다운 메뉴의 State와 URL 업데이트 (회사명)
  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocation = e.target.value;
    setSelectedLocation(newLocation);
    if (newLocation !== 'all') {
      setSearchParams({ location: newLocation }, { replace: true });
    } else {
      setSearchParams({ location: 'all' }, { replace: true });
    }
  };

  // RecruitCard를 클릭하면 id 값을 url에 반영
  const navigateToPost = (id: string) => {
    navigate(`?id=${id}`);
  };

  // 전체 State를 초기값으로 설정
  const resetSelection = () => {
    setSelectedCategories({
      commitment: [],
      department: [],
      team: [],
    });
    setSelectedLocation('all');
    setSearchParams({});
    setIsButtonVisible(true);
    navigate('.', { replace: true });
  };

  // 체크박스 노출여부
  const handleButtonToggle = () => {
    setIsButtonVisible(!isButtonVisible);
  };

  // NoResult 컴포넌트 분리 필요
  function NoResult() {
    return (
      <p>
        아쉽지만 지금 진행 중인 공고가 없습니다.
        <br /> 원하는 공고가 없다면{' '}
        <a href="https://www.neowiz.com/careers/recruit?id=c74ffadf-1e4e-4a3e-8ec3-fde9cc8f2aa3">
          인재 POOL에 등록
        </a>
        해 보세요
      </p>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className={cx('section-recruit')}>
      <div className={cx('recruit-filters')}>
        <div className={cx('recruit-header')}>
          <p className={cx('recruit-title')} onClick={handleButtonToggle}>
            진행 중인 공고
            <i className={cx('arrow', { up: isButtonVisible, down: !isButtonVisible })}></i>
          </p>

          <div className={cx('controls')}>
            <RecruitDropDownBox
              // title={'회사명'}
              locationList={locationList?.map((location) => location.name)}
              selectedItem={selectedLocation}
              setSelectedItem={setSelectedLocation}
              setIsButtonVisible={setIsButtonVisible}
              setSelectedCategories={setSelectedCategories}
            />
            <button className={cx('reset-button')} onClick={resetSelection}>
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.81196 13.7266C10.5243 13.7266 12.7312 11.5153 12.7312 8.79746C12.7312 6.07961 10.5243 3.86861 7.81196 3.86861C6.94356 3.86861 6.10864 4.09538 5.36741 4.52265L7.62406 6.41768L0 6.00263L4.21431 0L4.45308 2.43257C5.4829 1.88489 6.62615 1.59519 7.81196 1.59519C11.7753 1.59519 14.9997 4.82614 14.9997 8.79746C14.9997 12.7688 11.7753 16 7.81196 16C3.84862 16 0.624178 12.7688 0.624178 8.79746H2.89302C2.89302 11.5156 5.09983 13.7266 7.81196 13.7266Z"
                  fill="#232423"
                />
              </svg>
            </button>
          </div>
        </div>
        {isButtonVisible && (
          <div className={cx('category-checkbox')}>
            <p className={cx('category-title-team')}>직군</p>
            <CategoryButtonGroup
              category="team"
              list={teamList?.map((team) => ({
                name: team.name,
                count: team.count,
              }))}
              selected={selectedCategories.team}
              handleSelect={handleSelect}
              className={cx('category-group-team')}
            />
            <p className={cx('category-title-commitment')}>고용형태</p>
            <CategoryButtonGroup
              category="commitment"
              list={commitmentList?.map((commitment) => ({
                name: commitment.name,
                count: commitment.count,
              }))}
              selected={selectedCategories.commitment}
              handleSelect={handleSelect}
              className={cx('category-group-commitment')}
            />
            <p className={cx('category-title-department')}>프로젝트</p>
            <CategoryButtonGroup
              category="department"
              list={departmentList?.map((department) => ({
                name: department.name,
                count: department.count,
              }))}
              selected={selectedCategories.department}
              handleSelect={handleSelect}
              className={cx('category-group-department')}
            />
            {filteredPosts.length !== 0 && (
              <p className={cx('total-count')}>{filteredPosts.length}개의 채용공고가 있습니다</p>
            )}
            <div className={cx('empty-cell')}></div>
          </div>
        )}
      </div>
      <div className={cx('recruit-lists')}>
        {filteredPosts.length === 0 ? (
          <div className={cx('recruit-no-result')}>
            <NoResult />
          </div>
        ) : (
          <div className={cx('recruit-cards')}>
            {filteredPosts
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((post) => (
                <RecruitCard post={post} onClick={navigateToPost} />
              ))}
          </div>
        )}
      </div>
    </section>
  );
}
