// 2023.03월 news >> media로 메뉴 이름 변경
// 지금 이 파일은 media 메뉴의 index 파일
// 현재 폴더에서 news는 press와 의미가 같다
import { MainNav } from '../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';
import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { useTranslation } from 'react-i18next';
import { StickyGoTop } from '@/components/StickyGoTop';
import { Helmet } from 'react-helmet-async';
import Releases from './Releases';
import { StoryList } from '../mediaStory/StoryList';

const MediaPage = () => {
  const { t } = useTranslation('news');

  return (
    <div className="page-news">
      <Helmet>
        <title>네오위즈 - 네오위즈, 세상이 즐거워지는 마법</title>
      </Helmet>
      <MainNav />
      <PageHeader title="MEDIA">
        <BreadCrumbItem content="MEDIA" url="/media" className={'undefined'} />
      </PageHeader>
      <div className="sub-page-container">
        <StickyGoTop>
          {/* 보도자료 */}
          <Releases onlyTopBanner={true} />

          {/* 네오위즈 스토리 */}
          <StoryList onlyTopBanner={true} />
        </StickyGoTop>
      </div>
      <AppFooter />
    </div>
  );
};

export default MediaPage;
