import { ROUTES } from '@/constants';
import { siteMap } from '@/siteMap';
import { Trans, useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

export function HomeGames() {
  const { t } = useTranslation('home');
  const { ref, inView } = useInView({
    threshold: 0.8,
  });

  return (
    <section
      className={`section-games in-view--${inView}`}
      ref={ref}
      style={{
        backgroundPosition: `50% 50%`,
      }}>
      <div className="holder"></div>
      <div className="slogan">
        <h3>GAMES</h3>
        <p>
          <Trans t={t} i18nKey={'game.info'} />
        </p>
        <Link to={ROUTES['/games']} className="btn-more">
          <span>{t('bt.more')}</span>
          <i></i>
        </Link>
      </div>
      <div className="character">
        <span className="c6"></span>
        <span className="c5"></span>
        <span className="c4"></span>
        <span className="c3"></span>
        <span className="c2"></span>
        <span className="c1"></span>
      </div>
    </section>
  );
}
