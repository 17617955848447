import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { AppFooter } from '../../components/AppFooter';
import { BreadCrumbItem } from '../../components/BreadCrumbItem';
import { MainNav } from '../../components/MainNav';
import { PageHeader } from '../../components/PageHeader';
import { StickyGoTop } from '../../components/StickyGoTop';
import { RecruitList } from './RecruitList';
import { StickyBookMark } from '../careers/StickyBookMark';
import { CareersModel } from '@/model/careers-model';
import { RecruitView } from './RecruitView';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function CareersRecruit() {
  const [searchParams] = useSearchParams();

  const hasId = searchParams.has('id');
  const id = searchParams.get('id');

  return (
    <div className={cx('page-careers-recruit')} id="recruit">
      <Helmet>
        <title>네오위즈 채용</title>
      </Helmet>

      <MainNav />

      {hasId && id && <RecruitView id={id} />}
      {!hasId && (
        <>
          <PageHeader title="CAREERS">
            {/* careers 는 선택 못하게 해야함 */}
            <BreadCrumbItem content="CAREERS" url="/careers" />
            <BreadCrumbItem content="진행 중인 공고" url="/careers/recruit" className="undefined" />
          </PageHeader>
          <StickyGoTop className={cx('go-top')}>
            {/* <div className={cx('sub-page-container')}> */}
            <RecruitList />
            {/* </div> */}
          </StickyGoTop>
        </>
      )}
      <AppFooter />
    </div>
  );
}

export default CareersRecruit;
