import React from 'react';

export function getSiblings(e: EventTarget & HTMLElement) {
  // for collecting siblings
  let siblings: ChildNode[] = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild;

  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
}

export function toggleSiblingsClass(e: React.MouseEvent, toggleClass: string, targetToggle = true) {
  const targetClassList = e.currentTarget.classList;
  const siblingsEls = e.currentTarget.parentElement?.children;
  if (targetToggle && targetClassList.contains(toggleClass))
    return targetClassList.toggle(toggleClass);

  if (siblingsEls) {
    for (let i = 0; i < siblingsEls.length; i++) {
      siblingsEls[i].classList.remove(toggleClass);
    }
  }
  targetClassList.add(toggleClass);
}
