interface Props {
  label: string;
  necessary?: boolean;
  hasError?: boolean;
}

function PartnershipInputHolder({
  label,
  necessary = false,
  hasError = false,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div className={`partnership-input-holder has-error--${hasError}`}>
      <div className={`label`}>
        <span>{label}</span>
        {necessary && <span className="star">*</span>}
      </div>
      <div className={`content`}>{children}</div>
    </div>
  );
}

export default PartnershipInputHolder;
