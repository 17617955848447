import { useMatchMedia } from '@/hooks/useMatchMedia';
import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { API_PATH } from '@/constants';
import { useAPI } from '@/hooks/useAPI';
import { useInsideAPI } from '@/hooks/useInsideAPI';
import { CareersModel } from '@/model/careers-model';
import { WelfareCard } from '@/components/WelfareCard';
import { WelfareCategoryFilter } from '@/components/WelfareCategoryFilter';
import styles from './WelfareCardList.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

type Props = {
  setInview: (viewValue: CareersModel.CareerBookMark) => void;
};

export const WelfareCardList = ({ setInview }: Props) => {
  const { t } = useTranslation('careers');
  const { VIEW_MOBILE } = useMatchMedia();
  const [selectedCategory, setSelectedCategory] =
    useState<CareersModel.WelfareCategory>('FACILITY');
  const response = useInsideAPI(API_PATH['{lang}/careers/inside/neowiz'], selectedCategory);
  const { ref, inView, entry } = useInView({
    threshold: 0.45,
  });
  useEffect(() => {
    if (inView) {
      setInview('welfare');
      return;
    }
  }, [inView]);
  return (
    <section className={cx('section-welfare')} ref={ref}>
      <div className={cx('bookmark')} id="welfare" />
      <WelfareCategoryFilter
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div className={cx('content-welfare')}>
        {response &&
          response.data.map((item, i) => {
            const { mainTitle: title, subTitle: content, imageUrl: image } = item;
            return (
              <div key={i}>
                <WelfareCard title={title} content={content} image={image} />
              </div>
            );
          })}
      </div>
    </section>
  );
};
