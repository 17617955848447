import { MainNav } from '../../components/MainNav';
import { PageHeader } from '@/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { AppFooter } from '@/components/AppFooter';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useAPI } from '@/hooks/useAPI';
import { CareersModel } from '@/model/careers-model';
import { API_PATH, LINKS, ROUTES } from '@/constants';
import { supplant } from '@/util/string';
import { StickyGoTop } from '@/components/StickyGoTop';

//detail 관련 index, folder를 새로 만들어야할까.?
export function StoryView() {
  const { t, i18n } = useTranslation('etc');
  let { seq } = useParams<{ seq: string }>();

  // const fetcher = async () => {
  const { data: response, error } = useAPI<CareersModel.StoryRes>(
    supplant(`${API_PATH['{lang}/media/stories/{seq}']}`, {
      seq: seq,
    })
  );
  //이전글 다음글도 가져오기
  return (
    <div className="page-careers-story">
      <MainNav />
      <PageHeader title="네오위즈 NOW">
        <BreadCrumbItem content="MEDIA" url="/media" />
        <BreadCrumbItem content="네오위즈 NOW" url="/media/story" />
      </PageHeader>
      <StickyGoTop>
        <div className="sub-page-container">
          <section className={`section-view`}>
            {response && response.data && (
              <div className="view-contentBox">
                <div className="view-header">
                  <p className="title">{response.data.mainTitle}</p>
                  {/* <p className="date">{response.data.displayDate.split(' ')[0]}</p> */}
                </div>
                <div
                  className="view-body"
                  dangerouslySetInnerHTML={{ __html: response.data.contents }}
                />
                { response.data.btnYn == "Y" && (
                  <div className="apply-btn">
                    
                    { (String(response.data.url) !== "") ? ( 
                      <a href={String(response.data.url)} target="_blank" className='btn-more'>
                        <span>지원하기</span>
                        <i></i>
                      </a>
                    ) : (
                    <Link to={ROUTES['/careers/recruit']} className="btn-more">
                      <span>진행 중인 공고</span>
                      <i></i>
                    </Link>
                    )
                  }
                  </div>
                )}
              </div>
            )}
            <div className="view-nav">
              {response?.prevSeq && (
                <div className="before">
                  <Link to={`/media/story/${response.prevSeq}`}>
                    <p className="mobile">{t('bt.previous')}</p>
                    <p className="pc">{t('bt.previous')}</p>
                  </Link>
                </div>
              )}
              {!response?.prevSeq && (
                <div className="before none">
                  <a href="">
                    <p className="mobile">{t('bt.previous')}</p>
                    <p className="pc">{t('bt.no-prev-article')}</p>
                  </a>
                </div>
              )}
              <div className="list">
                <Link to={`/media/story`}>
                  <p>목록보기</p>
                </Link>
              </div>
              {response?.nextSeq && (
                <div className="next">
                  <Link to={`/media/story/${response.nextSeq}`}>
                    <p className="mobile">{t('bt.next')}</p>
                    <p className="pc"> {t('bt.next')}</p>
                  </Link>
                </div>
              )}
              {!response?.nextSeq && (
                <div className="next none">
                  <a href="">
                    <p className="mobile">{t('bt.next')}</p>
                    <p className="pc">{t('bt.no-next-article')}</p>
                  </a>
                </div>
              )}
            </div>
          </section>
        </div>
      </StickyGoTop>
      <AppFooter />
    </div>
  );
}

export default StoryView;
