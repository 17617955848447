import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { InvestorsModel } from '@/model/inverstors-model';
import { TInvestors } from '.';
import { useEffect } from 'react';
type Props = {
  data: InvestorsModel.stock | undefined;
  setInview: (viewValue: TInvestors) => void;
};
const Stock = ({ data, setInview }: Props) => {
  const { t } = useTranslation('investors');
  const { ref, inView, entry } = useInView({
    threshold: [0.9],
  });
  useEffect(() => {
    if (inView) {
      setInview('stock');
      return;
    }
  }, [inView]);
  return (
    <section className={`section-stock`} ref={ref}>
      <div className="bookmark" id={'stock'} />
      <p className="section-title">{t('stock')}</p>
      {data && (
        <div className="stock-info">
          <p className="stock-name">
            {t('neowiz')} <span className="stock-number">095660</span>
          </p>
          <p className="stock-price">{data?.currentPrice.toLocaleString('en-US')}</p>
          <p className="change">
            {t('change')}
            {/* 감소면 - 때고 blue로  */}
            <span className={`blue ${data.currentPrice - data.lastPrice < 0 && 'on'}`}>
              {data?.changePrice.toLocaleString('en-US')}
            </span>
            <span className={`red ${data.currentPrice - data.lastPrice >= 0 && 'on'}`}>
              {data?.changePrice.toLocaleString('en-US')}
            </span>
          </p>
          <p className="change">
            {t('changePercentage')}
            <span
              className={`blue ${
                data.currentPrice - data.lastPrice < 0 && 'on'
              }`}>{`${data?.changeRate}%`}</span>
            <span
              className={`red ${
                data.currentPrice - data.lastPrice >= 0 && 'on'
              }`}>{`${data?.changeRate}%`}</span>
          </p>
        </div>
      )}
      {data && (
        <div className="stock-table">
          <p className="date">{`${data?.stockDate} ${t('updated')}`}</p>
          <p className="unit">{t('stock.unit')}</p>
          <div className="stock-table__body">
            <table>
              <colgroup>
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
              </colgroup>
              <thead>
                <tr>
                  <td>{t('open')}</td>
                  <td>{t('hight')}</td>
                  <td>{t('low')}</td>
                  <td>{t('volume')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.openPrice.toLocaleString('en-US')}</td>
                  <td>{data?.highPrice.toLocaleString('en-US')}</td>
                  <td>{data?.lowPrice.toLocaleString('en-US')}</td>
                  <td>{data?.tradingVolume.toLocaleString('en-US')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="stock-table__body mob">
            <table>
              <colgroup>
                <col width="50%" />
                <col width="50%" />
              </colgroup>
              <thead>
                <tr>
                  <td>{t('open')}</td>
                  <td>{t('volume')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.openPrice.toLocaleString('en-US')}</td>
                  <td>{data?.tradingVolume.toLocaleString('en-US')}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <td>{t('hight')}</td>
                  <td>{t('low')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.highPrice.toLocaleString('en-US')}</td>
                  <td>{data?.lowPrice.toLocaleString('en-US')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="kosdaq">KOSDAQ</p>
          <div className="stock-table__kosdaq">
            <table>
              <tbody>
                <tr>
                  <th>{t('lastPrice')}</th>
                  <td className="line">{data?.lastPrice.toLocaleString('en-US')}</td>
                  <th>{t('highIn52')}</th>
                  <td>{data?.highIn52wks.toLocaleString('en-US')}</td>
                </tr>
                <tr>
                  <th>{t('upperLimit')}</th>
                  <td className="line">{data?.upperLimit.toLocaleString('en-US')}</td>
                  <th>{t('lowIn52')}</th>
                  <td>{data?.lowIn52wks.toLocaleString('en-US')}</td>
                </tr>
                <tr>
                  <th>{t('lowerLimit')}</th>
                  <td className="line">{data?.lowerLimit.toLocaleString('en-US')}</td>
                  <th>PER</th>
                  <td>{data?.per}</td>
                </tr>
                <tr className="dark">
                  <th colSpan={2}>{t('parValue')}</th>
                  <td colSpan={2}>{data?.parValue}</td>
                </tr>
                <tr className="dark">
                  <th colSpan={2}>{t('listedShared')}</th>
                  <td colSpan={2}>{data?.noOfListedShares.toLocaleString('en-US')}</td>
                </tr>
                <tr className="dark">
                  <th colSpan={2}>{t('marketCap')}</th>
                  <td colSpan={2}>{data?.marketCap.toLocaleString('en-US')}</td>
                </tr>
                <tr className="dark">
                  <th colSpan={2}>{t('listingDate')}</th>
                  <td colSpan={2}>{data?.listingDate}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="stock-table__kosdaq mob">
            <table>
              <tbody>
                <tr>
                  <th colSpan={2}>{t('lastPrice')}</th>
                  <td colSpan={2}>{data?.lastPrice.toLocaleString('en-US')}</td>
                </tr>
                <tr>
                  <th colSpan={2}>{t('upperLimit')}</th>
                  <td colSpan={2}>{data?.upperLimit.toLocaleString('en-US')}</td>
                </tr>
                <tr>
                  <th colSpan={2}>{t('lowIn52')}</th>
                  <td colSpan={2}>{data?.lowIn52wks.toLocaleString('en-US')}</td>
                </tr>
                <tr>
                  <th colSpan={2}>{t('lowerLimit')}</th>
                  <td colSpan={2}>{data?.lowerLimit.toLocaleString('en-US')}</td>
                </tr>
                <tr>
                  <th colSpan={2}>{t('highIn52')}</th>
                  <td colSpan={2}>{data?.highIn52wks.toLocaleString('en-US')}</td>
                </tr>
                <tr>
                  <th colSpan={2}>PER</th>
                  <td colSpan={2}>{data?.per}</td>
                </tr>
                <tr className="dark">
                  <th colSpan={2}>{t('parValue')}</th>
                  <td colSpan={2}>{data?.parValue}</td>
                </tr>
                <tr className="dark">
                  <th colSpan={2}>{t('listedShared')}</th>
                  <td colSpan={2}>{data?.noOfListedShares.toLocaleString('en-US')}</td>
                </tr>
                <tr className="dark">
                  <th colSpan={2}>{t('marketCap')}</th>
                  <td colSpan={2}>{data?.marketCap.toLocaleString('en-US')}</td>
                </tr>
                <tr className="dark">
                  <th colSpan={2}>{t('listingDate')}</th>
                  <td colSpan={2}>{data?.listingDate}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </section>
  );
};

export default Stock;
