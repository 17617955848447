import { DISABLE_EN_ROUTE_LIST, ROUTES } from '@/constants';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

/**
 * 영문으로 진입하면 안되는 메뉴는 홈으로 리다이렉트 한다.
 * @returns
 */
export function PreventRouteByLang() {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();

  if (i18n.language !== 'en') return null;

  let redirection = false;

  for (let i = 0; i < DISABLE_EN_ROUTE_LIST.length; i++) {
    if (redirection) break;
    redirection = pathname.indexOf(DISABLE_EN_ROUTE_LIST[i]) > -1;
  }

  return redirection ? <Navigate replace to={ROUTES['/']} /> : null;
}
