// 현재 페이지는 Media > 보도자료
import { MainNav } from '../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';
import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { useTranslation } from 'react-i18next';
import Releases from './Releases';
import { StickyGoTop } from '@/components/StickyGoTop';
import { Helmet } from 'react-helmet-async';

const StoriesPage = () => {
  const { t } = useTranslation('news');

  return (
    <div className="page-news">
      <Helmet>
        <title>네오위즈 - 네오위즈, 세상이 즐거워지는 마법</title>
      </Helmet>
      <MainNav />
      <PageHeader title="MEDIA">
        <BreadCrumbItem content="MEDIA" url="/media" />
        <BreadCrumbItem
          content={t('title').toUpperCase()}
          url="/media/press"
          className={'undefined'}
        />
      </PageHeader>
      <div className="sub-page-container">
        <StickyGoTop>
          <Releases onlyTopBanner={false} />
        </StickyGoTop>
      </div>
      <AppFooter />
    </div>
  );
};

export default StoriesPage;
