import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Spinner } from './components/Spinner';

export function Prelude() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Suspense>
    </ErrorBoundary>
  );
}
