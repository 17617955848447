import { NoResult } from '@/components/NoResult';
import { API_PATH, ROUTES } from '@/constants';
import { CareersModel, CategoryUpper } from '@/model/careers-model';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { TopStories } from './TopStories';
import { NavHashLink } from 'react-router-hash-link';
import { createCategoryParam, getQueryValues, useStroyAPI } from '@/hooks/useStoryAPI';
import { PaginationPage } from '@/components/PaginationPage';
import { SearchBar } from '@/components/SearchBar';
import GalleryListItem from '@/components/GalleryListItem';

interface Props {
  onlyTopBanner: boolean;
}

// ==========> COMPONENT
export const StoryList = ({ onlyTopBanner }: Props) => {
  const { t } = useTranslation('careers');
  const baseUrl = API_PATH['{lang}/media/stories'];
  const [ queryString, setQueryString ] = useSearchParams();
  const { searchText, category, size, page } = getQueryValues(queryString, ["searchText", "category", "size", "page"]);
  const { 
    setSearchText,
    setCurrentPage,
    storyData, 
    error 
  } = useStroyAPI(baseUrl, searchText, category, size, page);

  const listExist = storyData && storyData.data.length >= 1;
  const isLoading = !storyData && !error;

  return (
    <section className={`section-story news-banner__wrapper gallery-list-wrapper ${onlyTopBanner ? "only-top-banner" : ""}`}>
      { !onlyTopBanner && (
        <div className="career-story-filter common-filter">
          <h3>{t('story')}</h3>
          <div className="select-filter filter--escape-param">
            { CategoryUpper.map((categoryString, i) => (
              <NavHashLink 
                key={i} 
                to={`/media/story?page=1&size=9&${createCategoryParam(categoryString)}`}
                className={`${((categoryString === category) ? "query-active" : "")}`}
              >
                {(categoryString === "ALL") ? "전체" : categoryString}
              </NavHashLink>
            ))}
          </div>
          <SearchBar submitSearch={setSearchText}/>
        </div>
      )}


      { storyData && storyData.data && onlyTopBanner && (
        <>
          <p className="section-title">{t('story')}</p>
          <SearchBar submitSearch={setSearchText} redirect={true} link="/media/story"/>
          <TopStories data={storyData.data.slice(0, 4)} showDate={false} />
          <Link to="/media/story" className="btn-more-detail">+ 더보기</Link>
        </>
      )}

      {!listExist && !isLoading && <NoResult />}

      { !onlyTopBanner && 
        <>
          <div className="story-list gallery-list gallery--break-tablet">
            { listExist &&
              storyData &&
              storyData.data.map((item, i) => {
                return (
                  <GalleryListItem 
                    url='/media/story/'
                    seq={item.seq}
                    category={item.category}
                    key={item.seq}
                    title={item.topTitle}
                    img={item.horizontalImageUrl}
                    contents={item.subTitle}
                  />
                );
              })}
          </div>

          { listExist && storyData && (
            <PaginationPage
              setCurrentPage={setCurrentPage}
              totalPage={storyData.totalPage}
              currentPage={storyData.page}
            />
          )}

          <div className="apply-btn">
            <Link to={ROUTES['/careers/recruit']} className="btn-more">
              <span>지원하기</span>
              <i></i>
            </Link>
          </div>
        </>
      }
    </section>
  );
};
