import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-creative';

import { Navigation, Pagination, EffectFade, Autoplay, EffectCreative } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLayoutEffect, useState } from 'react';
import { HomeModel } from '@/model/home-model';
import { HomeSlide } from './HomeSlide';
import env from '@/config/env';
import { useMatchMedia } from '@/hooks/useMatchMedia';
import { API_PATH } from '@/constants';
import { useAPI } from '@/hooks/useAPI';

const BASE_PATH = env.CDN ?? '';

export const HomeSlider = () => {
  const { data } = useAPI<HomeModel.Banners>(API_PATH['{lang}/main/banner']);

  const [swiper, setSwiper] = useState<any>();
  const { VIEW_PC, VIEW_MOBILE, VIEW_TABLET } = useMatchMedia();

  useLayoutEffect(() => {
    if (!data || !swiper) return;
    swiper.update();
  }, [swiper, data]);

  return (
    <section className="home-slider">
      {data && data.data.length > 0 && (VIEW_PC || VIEW_TABLET) && (
        <Swiper
          modules={[Navigation, Pagination, EffectFade, Autoplay, EffectCreative]}
          pagination={{
            type: 'fraction',
          }}
          autoplay={{ delay: 15000 }}
          loop={true}
          navigation={true}
          effect={'fade'}
          fadeEffect={{ crossFade: true }}
          onSwiper={setSwiper}>
          {data.data
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((banner, i) => (
              <SwiperSlide key={i}>
                {(slideData) => <HomeSlide banner={banner} slideData={slideData} />}
              </SwiperSlide>
            ))}
          <a href="#home-about" className="next-section" />
        </Swiper>
      )}
      {data && data.data.length > 0 && VIEW_MOBILE && (
        <Swiper
          modules={[Navigation, Pagination, EffectFade, Autoplay, EffectCreative]}
          pagination={{
            type: 'fraction',
          }}
          autoplay={{ delay: 15000 }}
          loop={true}
          navigation={true}
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [-200, 0, -10],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          onSwiper={setSwiper}>
          {data.data
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((banner, i) => (
              <SwiperSlide key={i}>
                {(slideData) => <HomeSlide banner={banner} slideData={slideData} />}
              </SwiperSlide>
            ))}
          <a href="#home-about" className="next-section" />
        </Swiper>
      )}
    </section>
  );
};
