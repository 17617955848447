import { BASE_LIST_PER_PAGE } from '@/constants';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useAPI } from './useAPI';

export function useQueryBoard<ResModel>(
  paramEndPoint: string,
  pageSize = BASE_LIST_PER_PAGE,
  extraParam?: string
) {
  extraParam = extraParam ? '&' + extraParam : '';
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [endpoint, setEndpoint] = useState(`${paramEndPoint}?page=1&size=${pageSize}${extraParam}`);

  const navigate = useNavigate();
  const { pathname, search: locationSearch } = useLocation();
  const swrData = useAPI<ResModel>(endpoint);

  const historyPush = useCallback(
    (urlSearchText: string) => {
      const params = new URLSearchParams(urlSearchText);
      navigate({ pathname: pathname, search: params.toString() });
    },
    [pathname, navigate]
  );

  useEffect(() => {
    const params = new URLSearchParams(locationSearch);
    if (!params.has('searchText'))
      return historyPush(`?page=${currentPage}&size=${pageSize}${extraParam}`);
    if (params.has('searchText'))
      return historyPush(
        `?searchText=${params.get('searchText')}&page=${currentPage}&size=${pageSize}${extraParam}`
      );
  }, [currentPage]);

  useEffect(() => {
    const params = new URLSearchParams(locationSearch);
    if (!params.has('searchText'))
      return setEndpoint(
        `${paramEndPoint}?page=${params.get('page') || 1}&size=${pageSize}${extraParam}`
      );
    if (params.has('searchText'))
      return setEndpoint(
        `${paramEndPoint}?searchText=${params.get('searchText')}&page=${params.get(
          'page'
        )}&size=${pageSize}${extraParam}`
      );
  }, [locationSearch]);

  useEffect(() => {
    if (!searchText) return;
    setCurrentPage(1);
    historyPush(
      `?searchText=${encodeURIComponent(searchText)}&page=1&size=${pageSize}${extraParam}`
    );
  }, [searchText]);

  return {
    searchParams: new URLSearchParams(locationSearch),
    searchText,
    swrData,
    setCurrentPage,
    setSearchText,
  };
}
