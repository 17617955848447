import { BaseResponse, DateString, UrlString } from './BaseResponse';

/**
 * http://localhost:3000/api-doc/index.html#game-list
 */
export const GamePlatform = ['all', 'pc', 'mobile', 'console', 'new'] as const;
export declare namespace GameModel {
  type GameListRes = BaseResponse<Game[]>;
  type Platform = typeof GamePlatform[number];
  interface Game {
    title: string;
    description: string;
    genre: string;
    platform: string;
    tag: string;
    imgBrandUrl: UrlString;
    imgBrandThumUrl: UrlString;
    imgPcUrl: UrlString;
    imgMobileUrl: UrlString;
    linkHome: UrlString;
    linkYoutube: UrlString;
    linkFacebook: UrlString;
    linkPlaystore: UrlString;
    linkApplestore: UrlString;
    linkSteam: UrlString;
    linkTwitter: UrlString;
    linkInstagram: UrlString;
    startDate: DateString;
    endDate: DateString;
    displayOrder: number;
    topBanner: boolean;
  }
}
