import { Link, Route } from 'react-router-dom';

type Props = {
  content: string;
  url: string;
  className?: string;
};

export function BreadCrumbItem({ content, url, className: propsClassName = '' }: Props) {
  return (
    <li className={`breadcrumb-item ${propsClassName}`}>
      <Link to={url}>{content}</Link>
    </li>
  );
}
