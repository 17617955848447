import { BASE_LIST_PER_PAGE, LINKS } from './constants';

type I18nKey = string;
export interface ILinkInfo {
  name: I18nKey;
  path: string;
  target: '_self' | '_blank';
  subMenu?: ILinkInfo[];
}
export type ISiteMap = ILinkInfo[];

const newSiteMap: ISiteMap = [
  {
    name: 'about',
    path: '/about',
    target: '_self',
    subMenu: [
      {
        name: 'we are',
        path: '/about#we',
        target: '_self',
      },
      {
        name: 'history',
        path: '/about#history',
        target: '_self',
      },
    ],
  },
  {
    name: 'games',
    path: '/games',
    target: '_self',
    subMenu: [
      {
        name: 'all',
        path: '/games/all',
        target: '_self',
      },
      {
        name: 'pc',
        path: '/games/pc?list',
        target: '_self',
      },
      {
        name: 'mobile',
        path: '/games/mobile?list',
        target: '_self',
      },
      {
        name: 'console',
        path: '/games/console?list',
        target: '_self',
      },
      {
        name: 'newproject',
        path: '/games/new?list',
        target: '_self',
      },
    ],
  },
  {
    name: 'media',
    path: '/media',
    target: '_self',
    subMenu: [
      {
        name: 'report',
        path: `/media/press?page=1&size=${BASE_LIST_PER_PAGE}`,
        target: '_self',
      },
      {
        name: 'stroy',
        path: `/media/story?page=1&size=9`,
        target: '_self',
      },
    ],
  },
  {
    name: 'investors',
    path: '/investors',
    target: '_self',
    subMenu: [
      {
        name: 'stock',
        path: '/investors#stock',
        target: '_self',
      },
      {
        name: 'financials',
        path: '/investors#financial',
        target: '_self',
      },
      {
        name: 'earningsrelease',
        path: '/investors#earningsrelease',
        target: '_self',
      },
      {
        name: 'disclosure',
        path: '/investors#disclosure',
        target: '_self',
      },
      {
        name: 'announce',
        path: '/investors#announce',
        target: '_self',
      },
    ],
  },
  {
    name: 'careers',
    path: '/careers',
    target: '_self',
    subMenu: [
      {
        name: 'careers',
        path: '/careers',
        target: '_self',
      },
      {
        name: 'recruiting',
        path: `/careers/recruit`,
        target: '_self',
      },
    ],
  },
  {
    name: 'contact us',
    path: '/contact-us',
    target: '_self',
    subMenu: [],
  },
];

export const siteMap: ISiteMap = newSiteMap;
