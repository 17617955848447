import { fetcher } from '@/service/base-api';
import { supplant } from '@/util/string';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

export function useAPI<T>(path: string) {
  const { i18n } = useTranslation();
  // lang 표준과 서버 lang path가 달라 변환
  const koToKr = (s: string) => (s === 'ko' ? 'kr' : s);

  return useSWR(supplant(path, { lang: koToKr(i18n.language) }), (url) => fetcher<T>(url));
}
