import env from '@/config/env';

function endPointResolver(url: string) {
  return `${env.API_ENDPOINT}/${url}`;
}

export function fetcher<Response>(url: string, options?: RequestInit): Promise<Response>;
export function fetcher<Response, Request>(
  url: string,
  data?: Request,
  options?: RequestInit
): Promise<Response>;
export function fetcher<Response, Request>(
  url: string,
  data?: Request,
  options?: RequestInit
): Promise<Response> {
  return fetch(endPointResolver(url), {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json;charset=UTF-8',
      'X-API-KEY': import.meta.env.VITE_API_KEY,
    },
    body: data ? JSON.stringify(data) : undefined,
    ...options,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<Response>;
    })
    .catch((error: Error) => {
      console.error('API: ERR', error);
      throw error;
    });
}
