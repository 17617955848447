import { MainNav } from '../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';
import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';

import { StickyGoTop } from '@/components/StickyGoTop';
import { API_PATH } from '@/constants';
import { GameModel } from '@/model/game-model';
import { useAPI } from '@/hooks/useAPI';
import { GameSlider } from './GameSlider';
import { GameList } from './GameList';
import { Helmet } from 'react-helmet-async';

const GamesPage = () => {
  const { data } = useAPI<GameModel.GameListRes>(API_PATH['{lang}/game']);

  const filterGameList = (data: GameModel.Game[], key: keyof GameModel.Game) =>
    data.filter((data) => !!data[key]).sort((a, b) => a.displayOrder - b.displayOrder);

  return (
    <div className="page-games">
      <Helmet>
        <title>네오위즈 - 네오위즈, 세상이 즐거워지는 마법</title>
      </Helmet>
      <MainNav />
      <PageHeader title="GAMES">
        <BreadCrumbItem content="GAMES" url="/games/all" />
      </PageHeader>
      {data && <GameSlider data={filterGameList(data.data, 'topBanner')} />}
      <div className="sub-page-container">
        <StickyGoTop>{data && <GameList data={data.data} />}</StickyGoTop>
      </div>
      <AppFooter />
    </div>
  );
};

export default GamesPage;
