import { useEffect, useState } from 'react';
import { useAPI } from './useAPI';

export function useBoard<ResModel>(paramEndPoint: string, pageSize = 5) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [endpoint, setEndpoint] = useState(`${paramEndPoint}?page=1&size=${pageSize}`);

  const swrData = useAPI<ResModel>(endpoint);

  useEffect(() => {
    setEndpoint(`${paramEndPoint}?page=${currentPage}&size=${pageSize}`);
  }, [currentPage]);

  useEffect(() => {
    if (!searchText) return setEndpoint(`${paramEndPoint}?page=${currentPage}&size=${pageSize}`);
    setEndpoint(`${paramEndPoint}?searchText=${encodeURIComponent(searchText)}`);
  }, [searchText]);

  return {
    searchText,
    swrData,
    setCurrentPage,
    setSearchText,
    currentPage,
  };
}
