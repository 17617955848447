import { Fragment } from 'react';

export function HtmlLineBreakParser(content: string) {
  return content.includes('<br')
    ? content.split(/<br\s*\/?>/).map((line, index, arr) => (
        <Fragment key={index}>
          {line}
          {index < arr.length - 1 && <br />}
        </Fragment>
      ))
    : content;
}
