import { useMatchMedia } from '@/hooks/useMatchMedia';
import { GameModel } from '@/model/game-model';

type SlideProps = {
  banner: GameModel.Game;
  slideData: {
    isActive: boolean;
    isVisible: boolean;
    isDuplicate: boolean;
    isPrev: boolean;
    isNext: boolean;
  };
};

export function GameSlide({ banner }: SlideProps) {
  const { VIEW_MOBILE } = useMatchMedia();

  const {
    imgMobileUrl,
    imgPcUrl,
    imgBrandUrl,
    title,
    description,
    linkApplestore,
    linkFacebook,
    linkHome,
    linkPlaystore,
    linkSteam,
    linkYoutube,
    linkInstagram,
    linkTwitter,
  } = banner;

  return (
    <div className={`slide-content`}>
      <img src={VIEW_MOBILE ? imgMobileUrl : imgPcUrl} className="bg" />
      <section className="slide-body">
        <img src={imgBrandUrl} className={'brand-img'} />
        {title && <h3>{title}</h3>}
        {description && (
          <p>
            <span>{description}</span>
          </p>
        )}
        <div className={'links'}>
          {linkHome && (
            <a href={linkHome} className="white btn-link-home" target="_blank">
              Home
            </a>
          )}
          {linkYoutube && (
            <a href={linkYoutube} className="white btn-link-youtube" target="_blank">
              Youtube
            </a>
          )}
          {linkFacebook && (
            <a href={linkFacebook} className="white btn-link-facebook" target="_blank">
              Facebook
            </a>
          )}
          {linkPlaystore && (
            <a href={linkPlaystore} className="white btn-link-playstore" target="_blank">
              Play Store
            </a>
          )}
          {linkApplestore && (
            <a href={linkApplestore} className="white btn-link-appstore" target="_blank">
              App Store
            </a>
          )}
          {linkSteam && (
            <a href={linkSteam} className="white btn-link-facebook" target="_blank">
              steam
            </a>
          )}
          {linkInstagram && (
            <a href={linkInstagram} className="white btn-link-instagram" target="_blank">
              App Store
            </a>
          )}
          {linkTwitter && (
            <a href={linkTwitter} className="white btn-link-twitter" target="_blank">
              steam
            </a>
          )}
        </div>
      </section>
    </div>
  );
}
