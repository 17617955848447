import { RecruitModel } from '../../model/recruit-model';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styles from './RecruitDropDownBox.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

type Props = {
  locationList?: string[];
  selectedItem: string;
  setSelectedItem: (selectedItem: string) => void;
  setIsButtonVisible: (isButtonVisible: boolean) => void;
  setSelectedCategories: (selectedCategories: RecruitModel.CategoryState) => void;
};

export const RecruitDropDownBox = ({
  locationList,
  selectedItem,
  setSelectedItem,
  setIsButtonVisible,
  setSelectedCategories,
}: Props) => {
  const [onList, setOnList] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  // 토글 상태를 사용할거면 state  boolean 값으로 사용하는게 좋은거 같습니다.  toggle = () => setOnList((prev)=> !prev)
  // FIXME: click 이벤트로 수정 했습니다.
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  const handleOutsideClick = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setOnList(false);
    }
  };

  const handleSelectionChange = (value: string) => {
    setSelectedItem(value);
    setOnList((prev) => !prev);

    setSearchParams({ location: value }, { replace: true });

    setIsButtonVisible(value === '네오위즈' || value === 'all');
    setSelectedCategories({
      commitment: [],
      department: [],
      team: [],
    });
  };

  return (
    <div
      className={cx('category-dropdown-box')}
      ref={dropdownRef}
      onClick={(e) => {
        // e.stopPropagation(); // 버블링은 여기서 막았습니다. 버블링을 막으면 다른 드롭박스를 눌렀을때 event인식이 안됨
      }}>
      <a
        className={cx('category')}
        onClick={(e) => {
          setOnList((prev) => !prev);
        }}>
        {/* {`${selectedItem === 'all' ? title : selectedItem || '회사명'}`} */}
        {`${selectedItem === 'all' ? '회사명' : selectedItem}`}
        <span className={cx('category-list-btn')}>
          <i className={cx('bottom-arrow', { down: !onList })} />
        </span>
      </a>
      <div className={cx('category-list-box', { on: onList })}>
        <div className={cx('category-list')}>
          <ul key={0} className={cx('category-list-item')}>
            <li
              key={0}
              className={cx(`${'all' === selectedItem && 'active'}`)}
              onClick={() => handleSelectionChange('all')}>
              {'전체(ALL)'}
            </li>
          </ul>
          {locationList &&
            locationList.map((v, key) => {
              return (
                <ul key={key + 1} className={cx('category-list-item')}>
                  <li
                    key={v}
                    className={cx(`${v === selectedItem && 'active'}`)}
                    onClick={() => handleSelectionChange(v)}>{`${v}`}</li>
                </ul>
              );
            })}
        </div>
      </div>
    </div>
  );
};
