import { ExecOptionsWithStringEncoding } from 'child_process';
import { BaseResponse, DateNoDashString, HTMLString, UrlString } from './BaseResponse';

/**
 * http://localhost:3000/api-doc/index.html#main-banner
 */
export const OldCategory = ['interview', 'life'] as const;
export const Category = ['all', 'people', 'story', 'contents'] as const;
export const CategoryUpper = ['ALL', 'PEOPLE', 'STORY', 'CONTENTS'] as const;
export const CareerBookMark = ['join', 'welfare', 'faq'] as const;
export const WelfareCategory = ['FACILITY', 'BALANCE', 'HEALTH'] as const;
export const FaqCategory = ['APPLY', 'PROCESS', 'ETC'] as const;

export declare namespace CareersModel {
  type ListsRes = BaseResponse<StoryList[]> & {
    page: number;
    totalPage: number;
    category: string;
  };

  type StoryRes = BaseResponse<StoryList> & {
    nextSeq: number;
    prevSeq: number;
  };

  type InsideRes = BaseResponse<InsideList[]>;

  type WelfareRes = BaseResponse<WelfareList[]>;

  type Category = (typeof Category)[number];
  type CategoryUpper = (typeof CategoryUpper)[number];
  type WelfareCategory = (typeof WelfareCategory)[number];
  type FaqCategory = (typeof FaqCategory)[number];
  type CareerBookMark = (typeof CareerBookMark)[number];

  interface StoryList {
    seq: number;
    category: Category;
    topTitle: string;
    mainTitle: string;
    subTitle: string;
    hashtag: string;
    contents: string;
    url: ExecOptionsWithStringEncoding;
    horizontalImageUrl: string;
    verticalImageUrl: string;
    displayMain: string; // Y: N
    displayDate: string; // yyyy-MM-dd HH:mm:ss
    btnYn: 'Y' | 'N';
  }

  interface StoryReq {
    language: string;
    seq: number;
  }

  interface InsideList {
    urlId: string;
    careersImageUrl: string;
    orderby: number;
    displayDate: string;
    popup: string;
  }

  interface WelfareList {
    category: string;
    mainTitle: string;
    subTitle: string;
    hashtag: string;
    imageUrl: string;
  }

  interface FAQItem {
    title: string;
    category: string;
    content: string;
  }

  interface FAQData {
    TYPE: {
      [key: string]: string;
    };
    QUESTIONS: {
      [key: string]: string;
    };
    ANSWER: {
      [key: string]: string;
    };
  }
}
