import { MainNav } from '../../components/MainNav';
import { PageHeader } from '@/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { AppFooter } from '@/components/AppFooter';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useAPI } from '@/hooks/useAPI';
import { API_PATH } from '@/constants';
import { supplant } from '@/util/string';
import { NewsModel } from '@/model/news-model';
import { siteMap } from '@/siteMap';
import { StickyGoTop } from '@/components/StickyGoTop';

//detail 관련 index, folder를 새로 만들어야할까.?
const NewsView = () => {
  const { t } = useTranslation('etc');
  let { seq } = useParams<{ seq: string }>();

  const { data: response } = useAPI<NewsModel.NewsRes>(
    supplant(`${API_PATH['{lang}/media/press/{seq}']}`, {
      seq: seq,
    })
  );
  //이전글 다음글도 가져오기
  return (
    <div className="page-careers-story">
      <MainNav />
      <PageHeader title="NEWS">
        <BreadCrumbItem content="NEWS" url="/media"/>
        <BreadCrumbItem content="보도자료" url="/media/press" />
      </PageHeader>
      <StickyGoTop>
        <div className="sub-page-container">
          <section className={`section-view`}>
            {response && (
              <div className="view-contentBox">
                <div className="view-header">
                  <p className="title">{response.data.subject}</p>
                  <p className="date">
                    {response.data.regDate
                      .substring(0, 8)
                      .replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}
                  </p>
                </div>
                <div
                  className="view-body escape-reset"
                  dangerouslySetInnerHTML={{ __html: response.data.contents }}
                />
              </div>
            )}
            <div className="view-nav">
              {response?.prevSeq && (
                <div className="before">
                  <Link to={`/media/press/${response.prevSeq}`}>
                    <p className="mobile">{t('bt.previous')}</p>
                    <p className="pc">{t('bt.previous')}</p>
                  </Link>
                </div>
              )}
              {!response?.prevSeq && (
                <div className="before none">
                  <a href="">
                    <p className="mobile">{t('bt.previous')}</p>
                    <p className="pc">{t('bt.no-prev-article')}</p>
                  </a>
                </div>
              )}
              <div className="list">
                <Link to={`/media/press`}>
                  <p>목록보기</p>
                </Link>
              </div>
              {response?.nextSeq && (
                <div className="next">
                  <Link to={`/media/press/${response.nextSeq}`}>
                    <p className="mobile">{t('bt.next')}</p>
                    <p className="pc"> {t('bt.next')}</p>
                  </Link>
                </div>
              )}
              {!response?.nextSeq && (
                <div className="next none">
                  <a href="">
                    <p className="mobile">{t('bt.next')}</p>
                    <p className="pc">{t('bt.no-next-article')}</p>
                  </a>
                </div>
              )}
            </div>
          </section>
        </div>
      </StickyGoTop>
      <AppFooter />
    </div>
  );
};

export default NewsView;
