import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './Welfare.module.scss';

let cx = classNames.bind(styles);

type Props = {
  title: string;
  className: string;
};

const Welfare = ({ title, className }: Props) => {
  const { t } = useTranslation('careers'); // pages/careersRecruit/RecruitView.tsx에서만 사용된다.
  return (
    <div className={`${cx('welfare')} ${cx(className)}`}>
      <div className={`${cx('imgBox')} ${cx(title)}`}></div>
      <div className={cx('text')}>
        <p>{t(`${title}`)}</p>
        <p className={cx('content')}>{t(`${title}.text2`)}</p>
        <p className={cx('content')}>{t(`${title}.text3`)}</p>
      </div>
    </div>
  );
};

export default Welfare;
