import { useTranslation } from 'react-i18next';
import { InvestorsModel } from '@/model/inverstors-model';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { TInvestors } from '.';

type Props = {
  data: InvestorsModel.finances[] | undefined;
  setInview: (viewValue: TInvestors) => void;
};
const Financial = ({ data, setInview }: Props) => {
  const { t } = useTranslation('investors');
  const { ref, inView, entry } = useInView({
    threshold: 0.45,
  });
  useEffect(() => {
    if (inView) {
      setInview('financial');
      return;
    }
  }, [inView]);
  return (
    <section className={`section-financials`} ref={ref}>
      <div className="bookmark" id={'financial'} />
      <h3 className="sub-title">{t('financials')}</h3>
      <div className="financialInfo">
        <p>{t('financialTable')}</p>
        <p className="unit mob">{t('financials.unit')}</p>
      </div>
      <div className="financialTable assets">
        <p className="unit">{t('financials.unit')}</p>
        <div className="financial-table__body">
          {data?.length === 3 && (
            <table>
              <thead>
                <tr>
                  <td>{t('items')}</td>
                  <td>{data[2].year}</td>
                  <td>{data[1].year}</td>
                  <td>{data[0].year}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('currentAssets')}</td>
                  <td>{data[2].currentAssets}</td>
                  <td>{data[1].currentAssets}</td>
                  <td>{data[0].currentAssets}</td>
                </tr>
                <tr>
                  <td>{t('nonCurrentAssets')}</td>
                  <td>{data[2].nonCurrentAssets}</td>
                  <td>{data[1].nonCurrentAssets}</td>
                  <td>{data[0].nonCurrentAssets}</td>
                </tr>
                <tr>
                  <td>{t('totalAssets')}</td>
                  <td>{data[2].totalAssets}</td>
                  <td>{data[1].totalAssets}</td>
                  <td>{data[0].totalAssets}</td>
                </tr>
                <tr>
                  <td>{t('currentLiabilites')}</td>
                  <td>{data[2].currentLiabilities}</td>
                  <td>{data[1].currentLiabilities}</td>
                  <td>{data[0].currentLiabilities}</td>
                </tr>
                <tr>
                  <td>{t('nonCurrentLiabilites')}</td>
                  <td>{data[2].nonCurrentLiabilities}</td>
                  <td>{data[1].nonCurrentLiabilities}</td>
                  <td>{data[0].nonCurrentLiabilities}</td>
                </tr>
                <tr>
                  <td>{t('totalLiabilities')}</td>
                  <td>{data[2].totalLiabilities}</td>
                  <td>{data[1].totalLiabilities}</td>
                  <td>{data[0].totalLiabilities}</td>
                </tr>
                <tr>
                  <td>{t('commonStock')}</td>
                  <td>{data[2].commonStock}</td>
                  <td>{data[1].commonStock}</td>
                  <td>{data[0].commonStock}</td>
                </tr>
                <tr>
                  <td>{t('capitalSurplus')}</td>
                  <td>{data[2].capitalSurplus}</td>
                  <td>{data[1].capitalSurplus}</td>
                  <td>{data[0].capitalSurplus}</td>
                </tr>
                <tr>
                  <td>{t('retainedEarnings')}</td>
                  <td>{data[2].retainedEarnings}</td>
                  <td>{data[1].retainedEarnings}</td>
                  <td>{data[0].retainedEarnings}</td>
                </tr>
                <tr>
                  <td>{t('otherAssets')}</td>
                  <td>{data[2].otherComponentsOfEquity}</td>
                  <td>{data[1].otherComponentsOfEquity}</td>
                  <td>{data[0].otherComponentsOfEquity}</td>
                </tr>
                <tr>
                  <td>{t('noncontrollingInterests')}</td>
                  <td>{data[2].nonControllingInterests}</td>
                  <td>{data[1].nonControllingInterests}</td>
                  <td>{data[0].nonControllingInterests}</td>
                </tr>
                <tr>
                  <td>{t('totalEquity')}</td>
                  <td>{data[2].totalShareholdersEquity}</td>
                  <td>{data[1].totalShareholdersEquity}</td>
                  <td>{data[0].totalShareholdersEquity}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="financialInfo">
        <p>{t('IncomeStatement')}</p>
        <p className="unit mob">{t('financials.unit')}</p>
      </div>
      <div className="financialTable consolidated">
        <p className="unit">{t('financials.unit')}</p>
        {data?.length === 3 && (
          <table>
            <thead>
              <tr>
                <td>{t('items')}</td>
                <td>{data[2].year}</td>
                <td>{data[1].year}</td>
                <td>{data[0].year}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('revenues')}</td>
                <td>{data[2].revenues}</td>
                <td>{data[1].revenues}</td>
                <td>{data[0].revenues}</td>
              </tr>
              <tr>
                <td>{t('operatingExpenses')}</td>
                <td>{data[2].operatingExpenses}</td>
                <td>{data[1].operatingExpenses}</td>
                <td>{data[0].operatingExpenses}</td>
              </tr>
              <tr>
                <td>{t('operationIncome')}</td>
                <td>{data[2].operationIncome}</td>
                <td>{data[1].operationIncome}</td>
                <td>{data[0].operationIncome}</td>
              </tr>
              <tr>
                <td>{t('nonOperatingIncome')}</td>
                <td>
                  {(
                    Number(data[2].nonOperatingIncome.replaceAll(',', '')) -
                    Number(data[2].nonOperatingExpenses.replaceAll(',', ''))
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </td>
                <td>
                  {(
                    Number(data[1].nonOperatingIncome.replaceAll(',', '')) -
                    Number(data[1].nonOperatingExpenses.replaceAll(',', ''))
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </td>
                <td>
                  {(
                    Number(data[0].nonOperatingIncome.replaceAll(',', '')) -
                    Number(data[0].nonOperatingExpenses.replaceAll(',', ''))
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </td>
              </tr>
              <tr>
                <td>{t('profitbeforeTax')}</td>
                <td>{data[2].profitBeforeIncomeTax}</td>
                <td>{data[1].profitBeforeIncomeTax}</td>
                <td>{data[0].profitBeforeIncomeTax}</td>
              </tr>
              <tr>
                <td>{t('IncomeTax')}</td>
                <td>{data[2].incomeTaxExpense}</td>
                <td>{data[1].incomeTaxExpense}</td>
                <td>{data[0].incomeTaxExpense}</td>
              </tr>
              <tr>
                <td>{t('netIncome')}</td>
                <td>{data[2].netIncome}</td>
                <td>{data[1].netIncome}</td>
                <td>{data[0].netIncome}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </section>
  );
};

export default Financial;
