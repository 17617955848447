import { MainNav } from '../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';
import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const ContactUsPage = () => {
  const { t } = useTranslation('contact-us');
  const [roll, setRoll] = useState(1);

  useEffect(() => {
    const rolling = () => (roll > 2 ? setRoll(() => 1) : setRoll((prev) => prev + 1));
    const timer = setInterval(rolling, 5000);
    return () => clearInterval(timer);
  }, [roll]);

  return (
    <div className="page-contact-us">
      <Helmet>
        <title>네오위즈 - 네오위즈, 세상이 즐거워지는 마법</title>
      </Helmet>
      <MainNav />
      <PageHeader title="CONTACT US">
        {/* careers 는 선택 못하게 해야함 */}
        <BreadCrumbItem content="CONTACT US" url="/contactus" className="undefined" />
      </PageHeader>
      <div className="sub-page-container">
        <section className={`section-contact`}>
          <p className="section-title">{t('contactinfo')}</p>
          <div className={`contact-us-info `}>
            <div className={`contact-content1 roll--${roll}`}>
              <div>
                <h3>{t('contactinfo.r.t1')}</h3>
                <span>
                  <Trans t={t} i18nKey={'contactinfo.r.c1'} />
                </span>
              </div>
              <div>
                <Trans t={t} i18nKey={'contactinfo.r.t2'} />
                <p>{t('contactinfo.r.c2')}</p>
              </div>
              <div>
                <h3>{t('contactinfo.r.t3')}</h3>
                <p>{t('contactinfo.r.c3')}</p>
              </div>
            </div>
            <div className="contact-content2">
              <div className="sub-info">
                <h3>{t('recommend.c2.t')}</h3>
                <p>{t('recommend.c2.c')}</p>
              </div>
            </div>
            <div className="contact-content3">
              <div className="sub-info">
                <h3>{t('partnership.c3.t')}</h3>
                <p>{t('partnership.c3.c')}</p>
              </div>
            </div>
          </div>
          <div className="apply">
            <Link to={ROUTES['/contact-us/partnership']} className="apply-button">
              <span>{t('bt.contact.us')}</span>
              <i />
            </Link>
          </div>
          <section className="email">
            <h3 className="sub-title">EMAIL</h3>
            <div className="email-box">
              <p>{t('partnershipinquiries')}</p>
              <a href="mailto:ad@neowiz.com">ad@neowiz.com</a>
            </div>
            <div className="email-box">
              <p>{t('prinquiries')}</p>
              <a href="mailto:pr@neowiz.com">pr@neowiz.com</a>
            </div>
            <div className="email-box">
              <p>{t('irinquiries')}</p>
              <a href="mailto:ir@neowiz.com">ir@neowiz.com</a>
            </div>
            <div className="email-box">
              <p>{t('hr.inquiry')}</p>
              <a href="mailto:people@neowiz.com">people@neowiz.com</a>
            </div>
          </section>
          <section className="address">
            <h3 className="sub-title">ADDRESS</h3>
            <p className="address-address">{t('Detailed Address')}</p>
            <div className="line"></div>
            <a className="address-phone" href="tel:+8203180236600">
              TEL 031-8023-6600
            </a>
            <div className="map-holder">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d962.122743963389!2d127.10450599702025!3d37.39970082238691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b59e4937ccba3%3A0xa270503db9e55463!2zKOyjvCnrhKTsmKTsnITspog!5e0!3m2!1sko!2skr!4v1635322446113!5m2!1sko!2skr"
                allowFullScreen
                loading="lazy"
              />
            </div>
          </section>
        </section>
      </div>
      <AppFooter />
    </div>
  );
};

export default ContactUsPage;
