import env from '@/config/env';
import { RecruitModel } from '@/model/recruit-model';
import { organizeLeverCategories } from '@/hooks/organizeLeverCategories';

function endPointResolver(url: string) {
  return `${url}?skip=X&mode=json`;
}

export const lever_fetcher = async (url: string) => {
  try {
    const response = await fetch(endPointResolver(url));
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const totalPosting: RecruitModel.IPostingRes[] = await response.json();

    return {
      totalPosting,
    };
  } catch (error) {
    console.error('Lever API: Error', error);
    throw error;
  }
};
