import { useEffect, useState } from 'react';
import faqJson from '@/faq-data/lang.json';
import FaqCard from '@/components/FaqCard';
import { CareersModel } from '@/model/careers-model';
import { faqJsonParse } from '@/util/faqJsonParse';
import { FaqCategoryFilter } from '@/components/FaqCategoryFilter';
import { useInView } from 'react-intersection-observer';
import styles from './FaqCardList.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

type Props = {
  setInview: (viewValue: CareersModel.CareerBookMark) => void;
};

export const FaqCardList = ({ setInview }: Props) => {
  const [faqCategory, setFaqCategory] = useState<CareersModel.FaqCategory>('APPLY');
  const [faqItemList, setFaqItemList] = useState<CareersModel.FAQItem[]>([]);
  const [categorizedFaqItemList, setCategorizedFaqItemList] = useState<CareersModel.FAQItem[]>([]);
  const [isContentVisible, setIsContentVisible] = useState<Array<boolean>>(
    faqItemList.map(() => false)
  );
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
  });

  const toggleContentVisible = (index: number) => {
    const newIsContentVisible = [...isContentVisible];
    newIsContentVisible[index] = !newIsContentVisible[index];
    setIsContentVisible(newIsContentVisible);
  };

  const closeAllContent = () => {
    setIsContentVisible(faqItemList.map(() => false));
  };

  useEffect(() => {
    if (inView) {
      setInview('faq');
      return;
    }
  }, [inView]);

  useEffect(() => {
    setFaqItemList(faqJsonParse(faqJson));
  }, []);

  useEffect(() => {
    const categorizedFaqItems = {
      APPLY: faqItemList.filter((value) => value.category === '지원문의'),
      PROCESS: faqItemList.filter((value) => value.category === '전형문의'),
      ETC: faqItemList.filter((value) => value.category === '기타문의'),
    };
    setCategorizedFaqItemList(categorizedFaqItems[faqCategory]);
  }, [faqItemList, faqCategory]);

  return (
    <section className={cx('section-faq')} ref={ref}>
      <div className={cx('bookmark')} id="faq" />
      <FaqCategoryFilter
        faqCategory={faqCategory}
        setFaqCategory={setFaqCategory}
        closeAllContent={closeAllContent}
      />
      <div className={cx('faq-list')}>
        {categorizedFaqItemList.map((faqItem, idx) => {
          return (
            <FaqCard
              key={idx}
              type={faqItem.category}
              title={faqItem.title}
              content={faqItem.content}
              isContentVisible={isContentVisible[idx]}
              setIsContentVisible={() => toggleContentVisible(idx)}
            />
          );
        })}
      </div>
      <div className={cx('faq-question')}>
        <p className={cx('desktop-text')}>
          채용에 대해 궁금하신 사항은 FAQ를 먼저 참고해주시기 바랍니다. FAQ에서 원하는 답변을 <br />
          찾지 못할 경우에는 <span>talent@neowiz.com</span>로 문의 주시기 바랍니다.
        </p>
        <p className={cx('mobile-text')}>
          채용에 대해 궁금하신 사항은 FAQ를 먼저 참고해주시기 바랍니다. <br />
          FAQ에서 원하는 답변을 찾지 못할 경우에는 <span>talent@neowiz.com</span>로 문의 주시기
          바랍니다.
        </p>
      </div>
    </section>
  );
};
