import { CareersModel } from '@/model/careers-model';
import { Link } from 'react-router-dom';

type Props = {
  data: CareersModel.StoryList[];
  showDate?: boolean;
};
export const TopStories = ({ data, showDate = true }: Props) => {
  return (
    <div className="news-banners">
      {data &&
        data.map((item, i) => {
          return (
            <div className="news-banner" key={i}>
              <Link to={`/media/story/${item.seq}`}>
                <div className="banner-item">
                  <div className="img-box img">
                    {item.verticalImageUrl && (
                      <img className="background-img" src={item.verticalImageUrl} alt="" />
                    )}
                  </div>
                  <div className="gradient-img"></div>
                  {/* <img className="gradient-img" src={gradient} alt="" /> */}
                  <div className="banner-contents">
                    <div className={`title ${showDate ? '' : 'no-date'}`}>
                      <p dangerouslySetInnerHTML={{ __html: item.topTitle }}></p>
                    </div>
                    { showDate && (
                      <p className="date">
                        {item.displayDate.substring(0, 9)}
                      </p>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </div>
  );
};
