import { CareersModel } from '@/model/careers-model';
import footerCi from '@images/footer/footer-ci.png';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavHashLink } from 'react-router-hash-link';
import styles from './StickyBookMark.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  inView: CareersModel.CareerBookMark;
};

export function StickyBookMark({ inView }: Props) {
  return (
    <div className={cx('mark-holder')}>
      <div className={cx('bar', `in-view--${inView}`)}>
        <NavHashLink to={'#join'} className={cx('btn-go', 'join')} smooth>
          <span>합류절차</span>
        </NavHashLink>
        <NavHashLink to={'#welfare'} className={cx('btn-go', 'welfare')} smooth>
          <span>네오위즌 혜택</span>
        </NavHashLink>
        <NavHashLink to={'#faq'} className={cx('btn-go', 'faq')} smooth>
          <span>FAQ</span>
        </NavHashLink>
      </div>
    </div>
  );
}
