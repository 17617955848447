// 2023.03월 careers 카테고리 아래에서 media(전 news) 카테고리 아래로 이동함
// 현재는 media > 네오위즈 now의 페이지임
import { MainNav } from '../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';
import { StickyGoTop } from '@/components/StickyGoTop';
import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { StoryList } from './StoryList';
import { Helmet } from 'react-helmet-async';
import { useAPI } from '@/hooks/useAPI';
import { CareersModel } from '@/model/careers-model';
import { API_PATH } from '@/constants';

const CareersStory = () => {
  //페이지 별 조회안댐..
  return (
    <div className="page-careers-story">
      <Helmet>
        <title>네오위즈 NOW</title>
      </Helmet>
      <MainNav />
      <PageHeader title="MEDIA">
        <BreadCrumbItem content="MEDIA" url="/media" />
        <BreadCrumbItem content="네오위즈 NOW" url="/media/story" className="undefined" />
      </PageHeader>
      <div className="sub-page-container">
        <StickyGoTop>
          <StoryList onlyTopBanner={false}/>
        </StickyGoTop>
      </div>
      <AppFooter />
    </div>
  );
};

export default CareersStory;
