import { CareersModel } from "@/model/careers-model";
import { useQueryBoard } from "./useQueryBoard";

interface QueryString {
  [key: string]: string;
  category: CareersModel.CategoryUpper;
}

/**
 * FUNCTION : queryString에서 값 뽑기 (category는 기본적으로 ALL로 반환)
 * @param queryStrings useSearchParams()로 가져온 queryString 값
 * @param values 파라미터에서 가져오고자 하는 값의 배열. ex) ["page", "size"]
 * @returns { [key: string]: string }
 */
export function getQueryValues (queryStrings: URLSearchParams, values: string[]) {
  const result: QueryString = { category: "ALL" };
  
  values.reduce((prev, cur): QueryString => {
    const targetQueryString = queryStrings.get(cur);
    
    if(!targetQueryString) return prev;
    return Object.defineProperty(prev, String(cur), { value: targetQueryString, writable: true });
  }, result);
  
  return result;
}

/**
 * FUNCTION : 카테고리 명(uppercase)으로 category query string 생성
 * @param category 카테고리 종류
 * @returns category={카테고리명}
 */
export function createCategoryParam (category: CareersModel.CategoryUpper) {
  const base = "category=";
  return (category === "ALL") ? "" : (base + category);
}

// ==========> Hooks
export function useStroyAPI<ResModel>(
  paramEndPoint: string, 
  searchText: string | undefined,
  category: CareersModel.CategoryUpper = "ALL", 
  page: string = "1",
  size: string = "9",
) {
  const categoryQueryString = createCategoryParam(category);
  const { 
    setSearchText, 
    setCurrentPage, 
    swrData: { data: storyData, error} 
  } = useQueryBoard<CareersModel.ListsRes>(paramEndPoint, 9, categoryQueryString);

  return { 
    setSearchText, // 이렇게 전달만 하는 방식이 괜...찮은가?
    setCurrentPage, 
    storyData,
    error,
  };
}