import { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { NoticeModal } from './components/NoticeModal';
import { Portal } from './components/Portal';
import { PreventRouteByLang } from './components/PreventRouteByLang';
import { ScrollToTop } from './components/ScrollToTop';
import env from './config/env';
import { API_PATH, BASE_LIST_PER_PAGE, ROUTES } from './constants';
import { useAPI } from './hooks/useAPI';
import { NoticePopupModel } from './model/notice-popup-model';
import AboutPage from './pages/about';
import CareersPage from './pages/careers';
import CareersRecruit from './pages/careersRecruit';
import Story from './pages/mediaStory';
import StoryView from './pages/mediaStory/StoryView';
import ContactUsPage from './pages/contact-us';
import ContactUsPartnershipPage from './pages/contact-us/partnership';
import GamesPage from './pages/games';
import HomePage from './pages/home';
import InvestorsPage from './pages/investors';
import InvestorView from './pages/investors/InvestorView';
import MediaPage from './pages/media';
import StoriesPage from './pages/media/NewsPage';
import NewsView from './pages/media/NewsView';
import NotFound from './pages/NotFound';
import TestApi from './pages/TestApi';
import TestI18n from './pages/TestI18n';

function GAPageViewSender() {
  const location = useLocation();

  useEffect(() => {
    const currentPage = location.pathname + location.search; // 쿼리 스트링 포함
    ReactGA.send({ hitType: 'pageview', page: currentPage });
  }, [location]);

  return null;
}

function App() {
  const { data: popRes } = useAPI<NoticePopupModel.PopupsRes>(API_PATH['{lang}/popup']);
  const { i18n } = useTranslation();
  const { t } = useTranslation('common');

  useEffect(() => {
    document.title = t('title');
  }, [i18n.language]);

  return (
    <Fragment>
      <Router basename={env.basePath}>
        <PreventRouteByLang />

        <GAPageViewSender />

        <ScrollToTop />

        <Routes>
          <Route path={ROUTES['/']} element={<HomePage />} />
          <Route path={ROUTES['/about']} element={<AboutPage />} />

          <Route path={ROUTES['/games']} element={<Navigate replace to={'/games/all'} />}></Route>
          <Route path={ROUTES['/games/:filter']} element={<GamesPage />} />

          <Route path={ROUTES['/media']} element={<MediaPage />} />
          <Route path={ROUTES['/media/press']} element={<StoriesPage />} />
          <Route path={ROUTES['/media/press/:seq']} element={<NewsView />} />
          <Route path={ROUTES['/media/story/:seq']} element={<StoryView />} />
          <Route path={ROUTES['/media/story']} element={<Story />} />

          <Route path={ROUTES['/careers']} element={<CareersPage />} />
          <Route path={ROUTES['/careers/recruit']} element={<CareersRecruit />} />

          <Route path={ROUTES['/investors']} element={<InvestorsPage />} />
          <Route path={ROUTES['/investors/announcement/:seq']} element={<InvestorView />} />

          <Route path={ROUTES['/contact-us/partnership']} element={<ContactUsPartnershipPage />} />
          <Route path={ROUTES['/contact-us']} element={<ContactUsPage />} />

          {env.DEV && <Route path={ROUTES['/dev/i18n']} element={<TestI18n />} />}
          {env.DEV && <Route path={ROUTES['/dev/api']} element={<TestApi />} />}

          {/* 404 */}
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </Router>

      {popRes && popRes.data.length > 0 && (
        <Portal>
          {popRes.data.map((popup, i) => (
            <NoticeModal style={{}} key={i} noticePopup={popup} />
          ))}
        </Portal>
      )}
    </Fragment>
  );
}

export default App;
