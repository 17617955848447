import { MainNav } from '@/components/MainNav';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation('etc');
  return (
    <div className="page-not-found">
      <MainNav />
      <section className="container">
        <h4>404</h4>
        <p className="sub-title">{t('error.text1')}</p>
        <p className="info">{t('error.text2')}</p>
        <div>
          <Link to="/" className="btn-home">
            {t('bt.main')}
          </Link>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
