export function groupByToObject<T, K extends keyof any>(list: T[], getKey: (item: T) => K) {
  return list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
}

export function groupByToArray<T, K extends keyof any>(list: T[], keyGetter: (item: T) => K) {
  const result = groupByToObject(list, keyGetter);
  return (Object.keys(result) as Array<keyof typeof result>).map((key) => ({
    key,
    group: result[key],
  }));
}
