import { useEffect, useState } from 'react';
import { BreadCrumbItem } from './BreadCrumbItem';
type Props = {
  title: string;
  children: React.ReactNode;
};

export function PageHeader({ title, children }: Props) {
  return (
    <div className="page-header">
      <div className="header-box">
        <div className="header-title">
          <h2>{title.toUpperCase()}</h2>
        </div>
        <div className="breadcrumbBox">
          <ul className="breadcrumb-item-list">
            <BreadCrumbItem className="home" content="HOME" url="/" />
            {/* 자식으로 BreadCrumbItem 들 첨부 */}
            {children}
          </ul>
        </div>
      </div>
    </div>
  );
}
