import classNames from 'classnames/bind';
import styles from './RecruitCard.module.scss';
import { Link } from 'react-router-dom';
import { RecruitModel } from '@/model/recruit-model';

let cx = classNames.bind(styles);

type RecruitCardProps = {
  post: RecruitModel.IPostingRes;
  onClick: (id: string) => void;
  className?: string;
};

export const RecruitCard = ({ post, onClick, className }: RecruitCardProps) => {
  return (
    <div className={`${cx('card-wrapper')} ${className}`} key={post.id}>
      <div className={cx('card-items')}>
        <div className={cx('category')} onClick={() => onClick(post.id)}>
          <div className={cx('location')}>{post.categories.location}</div>
          {post.categories.department &&
            post.categories.department !== 'Other teams' && ( // department값이 Other teams이면 예외처리
              <div className={cx('department')}>{post.categories.department}</div>
            )}
          <div className={cx('team')}>{post.categories.team}</div>
        </div>
        <div className={cx('title')}>
          <Link
            to={{
              pathname: `/careers/recruit`,
              search: `?id=${post.id}`,
            }}>
            <div className={cx('text')}>{post.text}</div>
            {post.categories.commitment && (
              <div className={cx('commitment')}>{post.categories.commitment}</div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};
