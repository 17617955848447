import { useState } from 'react';
import { CareersModel } from '@/model/careers-model';
import styles from './FaqCategoryFilter.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  faqCategory: string;
  setFaqCategory: (setFaqCategory: CareersModel.FaqCategory) => void;
  closeAllContent: () => void;
};

export const FaqCategoryFilter = ({ faqCategory, setFaqCategory, closeAllContent }: Props) => {
  const handleCategoryClick = (category: CareersModel.FaqCategory) => {
    setFaqCategory(category);
    closeAllContent();
  };

  return (
    <div className={cx('category-filter')}>
      <p>FAQ</p>
      <div className={cx('select-filter')}>
        <a
          className={cx({ active: faqCategory === 'APPLY' })}
          onClick={() => handleCategoryClick('APPLY')}>
          지원문의
        </a>
        <span></span>
        <a
          className={cx({ active: faqCategory === 'PROCESS' })}
          onClick={() => handleCategoryClick('PROCESS')}>
          전형문의
        </a>
        <span></span>
        <a
          className={cx({ active: faqCategory === 'ETC' })}
          onClick={() => handleCategoryClick('ETC')}>
          기타문의
        </a>
      </div>
    </div>
  );
};
