import { Bookmark } from '@/components/BookMark';
import { NoResult } from '@/components/NoResult';
import { GameModel, GamePlatform } from '@/model/game-model';
import { useLayoutEffect } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

type Props = {
  data: GameModel.Game[];
};

export function GameList({ data }: Props) {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  let { filter } = useParams<{ filter: GameModel.Platform }>();
  const [list, setList] = useState<GameModel.Game[]>(data);
  const [filterName, setFilterName] = useState<GameModel.Platform>(filter ?? 'all');
  const i18nKeyResolver = (key: string) => (key === 'new' ? 'newproject' : key);

  useEffect(() => {
    setList(data);
  }, [data]);

  useEffect(() => {
    setFilterName(filter ?? 'all');
  }, [filter]);

  useEffect(() => {
    if (data.length < 1) return;

    setList(
      data.filter(
        ({ platform }) =>
          filterName === 'all' || String(platform).toLowerCase().indexOf(filterName) > -1
      )
    );
  }, [data, filterName]);

  useLayoutEffect(() => {
    if (searchParams.has('list')) {
      const element = document.querySelector('.game-list');
      const headerOffset = 70; // TODO: 모바일일 때 다르게
      const elementPosition = element?.getBoundingClientRect().top || 0;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [searchParams, filterName]);

  const listExist = list.length > 0;

  return (
    <section className="game-list">
      <Bookmark id={'game-list'} />
      <div className="game-filter common-filter">
        <h3>{t(i18nKeyResolver(filterName))}</h3>
        <div className={`select-filter`}>
          {GamePlatform.map((platformString, i) => (
            <NavHashLink key={i} to={`/games/${platformString}?list`}>
              {t(i18nKeyResolver(platformString))}
            </NavHashLink>
          ))}
        </div>
      </div>
      <div className="sticky-shadow"></div>
      {listExist && (
        <ul>
          {list.map((item, i) => (
            <GameListItem item={item} key={item.title} />
          ))}
        </ul>
      )}
      {!listExist && <NoResult />}
    </section>
  );
}

// NOTE: https://www.tutorialguruji.com/react-js/react-transition-group-how-to-pass-ref-to-mapped-component-to-avoid-warning-finddomnode-is-deprecated-in-strictmode/
type ItemProps = { item: GameModel.Game };

const GameListItem = ({ item, ...rest }: ItemProps) => {
  const nodeRef = useRef(null);

  const {
    imgBrandThumUrl,
    title,
    description,
    linkApplestore,
    linkFacebook,
    linkHome,
    linkPlaystore,
    linkYoutube,
    linkSteam,
    linkTwitter,
    linkInstagram,
  } = item;

  return (
    <li ref={nodeRef}>
      <div className={'thumb-nail'}>
        <img src={imgBrandThumUrl} alt="" />
      </div>
      <strong>{title}</strong>
      <p>{description}</p>
      <div className="links">
        {linkHome && (
          <a href={linkHome} className="btn-link-home" target="_blank">
            Home
          </a>
        )}
        {linkYoutube && (
          <a href={linkYoutube} className="btn-link-youtube" target="_blank">
            Youtube
          </a>
        )}
        {linkApplestore && (
          <a href={linkApplestore} className="btn-link-appstore" target="_blank">
            App Store
          </a>
        )}
        {linkPlaystore && (
          <a href={linkPlaystore} className="btn-link-playstore" target="_blank">
            Play Store
          </a>
        )}
        {linkFacebook && (
          <a href={linkFacebook} className="btn-link-facebook" target="_blank">
            Facebook
          </a>
        )}
        {linkSteam && (
          <a href={linkSteam} className="btn-link-facebook" target="_blank">
            steam
          </a>
        )}

        {linkInstagram && (
          <a href={linkInstagram} className="btn-link-instagram" target="_blank">
            instagram
          </a>
        )}

        {linkTwitter && (
          <a href={linkTwitter} className="btn-link-twitter" target="_blank">
            twitter
          </a>
        )}
      </div>
    </li>
  );
};
