import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { BASE_LIST_PER_PAGE } from '@/constants';
import { update } from 'lodash';
type Props = {
  setCurrentPage: (page: number) => void;
  updatePageParam?: (
    location: string,
    department: string,
    workType: string,
    currentPage: number
  ) => void;
  currentParam?: Array<string>;
  totalPage: number;
  currentPage: number;
  itemsCountPerPage?: number;
};

// 왼쪽 누름 page-1 , 오른쪽 누름 page+1 ,(아닌가 전체 페이지의 이동인가? +5 ) 현재 페이지는 빨간색, 전체 페이지,
export function PaginationPage({
  setCurrentPage,
  updatePageParam,
  currentParam,
  totalPage,
  currentPage,
  itemsCountPerPage = BASE_LIST_PER_PAGE,
}: Props) {
  return (
    <div className={`paging-box`}>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalPage * itemsCountPerPage}
        pageRangeDisplayed={10}
        prevPageText={''}
        nextPageText={''}
        onChange={(e) => {
          setCurrentPage(e);
          if (currentParam != undefined && updatePageParam != undefined) {
            // 페이징 변경 시 쿼리 스트링 변경
            updatePageParam(currentParam[0], currentParam[1], currentParam[2], e);
          }
        }}></Pagination>
    </div>
  );
}
