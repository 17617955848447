import { MainNav } from '../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';
import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import AboutWeAre from './AboutWeAre';
import AboutHistory from './AboutHistory';
import { Helmet } from 'react-helmet-async';

const AboutPage = () => {
  return (
    <div className="page-about" id={'we'}>
      <Helmet>
        <title>네오위즈 - 네오위즈, 세상이 즐거워지는 마법</title>
      </Helmet>
      <MainNav />
      <PageHeader title="ABOUT">
        <BreadCrumbItem content="ABOUT" url="/about" />
      </PageHeader>
      <div className="sub-page-container">
        <AboutWeAre />
        <AboutHistory />
      </div>
      <AppFooter />
    </div>
  );
};

export default AboutPage;
