export const language = ['ko', 'en'] as const;
export type TLanguage = typeof language[number];

export const API_PATH = {
  /**
   * http://localhost:3000/api-doc/index.html#main-banner
   */
  '{lang}/main/banner': '{lang}/main/banner',
  /**
   * http://localhost:3000/api-doc/index.html#about-history
   */
  '{lang}/about/history': '{lang}/about/history',
  /**
   * http://localhost:3000/api-doc/index.html#game-list
   */
  '{lang}/game': '{lang}/game',
  /**
   * http://localhost:3000/api-doc/index.html#news-press
   */
  '{lang}/media/press': '{lang}/media/press',
  /**
   * http://localhost:3000/api-doc/index.html#news-press-view
   */
  '{lang}/media/press/{seq}': '{lang}/media/press/{seq}',
  /**
   * http://localhost:3000/api-doc/index.html#investors-all
   */
  '{lang}/investors': '{lang}/investors',
  /**
   * http://localhost:3000/api-doc/index.html#investors-disclosure
   */
  '{lang}/investors/disclosure': '{lang}/investors/disclosure',
  /**
   * http://localhost:3000/api-doc/index.html#investors-announcement
   */
  '{lang}/investors/announcement': '{lang}/investors/announcement',
  /**
   * http://localhost:3000/api-doc/index.html#investors-announcement-view
   */
  '{lang}/investors/announcement/{seq}': '{lang}/investors/announcement/{seq}',
  /**
   * http://localhost:3000/api-doc/index.html#careers-inside-neowiz
   */
  '{lang}/careers/inside/neowiz': '{lang}/careers/inside/neowiz',
  /**
   * http://localhost:3000/api-doc/index.html#careers-stories
   */
  '{lang}/media/stories': '{lang}/media/stories',
  /**
   * /careers/careers'
   */
  '{lang}/careers/careers': '{lang}/careers/careers',
  /**
   * http://localhost:3000/api-doc/index.html#careers-stories-view
   */
  '{lang}/media/stories/{seq}': '{lang}/media/stories/{seq}',
  /**
   * http://localhost:3000/api-doc/index.html#policy-list
   */
  '{lang}/policy/{type}': '{lang}/policy/{type}',
  /**
   * http://localhost:3000/api-doc/index.html#policy-view
   */
  '{lang}/policy/{type}/{seq}': '{lang}/policy/{type}/{seq}',
  /**
   * http://localhost:3000/api-doc/index.html#popup-list
   */
  '{lang}/popup': '{lang}/popup',
  /**
   * http://localhost:3000/api-doc/index.html#contact-reception
   */
  'contact': 'contact',
  /**
   * https://api.lever.co/v0/postings/neowiz
   */
  'lever': 'https://api.lever.co/v0/postings/neowiz',
};

export const LINKS = {
  RECRUIT: 'https://neowiz.recruiter.co.kr',
  APPLY: 'https://jobs.lever.co/neowiz/c74ffadf-1e4e-4a3e-8ec3-fde9cc8f2aa3/apply',
};

export const ROUTES = {
  '/': '/',
  '/about': '/about',
  '/games': '/games',
  '/games/:filter': '/games/:filter',
  '/media': '/media',
  '/media/press': '/media/press',
  '/media/press/:seq': '/media/press/:seq',
  '/media/story': '/media/story',
  '/media/story/:seq': '/media/story/:seq',
  '/careers': '/careers',
  '/careers/life': '/careers/life',
  '/careers/recruit': '/careers/recruit',
  '/careers/recruit/:seq': '/careers/recruit/:seq',
  '/careers/faq': '/careers/faq',
  '/investors': '/investors',
  '/investors/announcement/:seq': '/investors/announcement/:seq',
  '/contact-us': '/contact-us',
  '/contact-us/partnership': '/contact-us/partnership',
  '/dev/i18n': '/dev/i18n',
  '/dev/api': '/dev/api',
};

export const BASE_LIST_PER_PAGE = 10;

export const DISABLE_EN_ROUTE_LIST = [
  ROUTES['/media'],
  ROUTES['/careers'],
  '/investors#disclosure',
  '/investors#announce',
];
