import footerCi from '@images/footer/footer-ci.png';
import { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};
export function StickyGoTop({ children, className }: Props) {
  return (
    <div className="go-top-wrapper">
      {children}

      <div className={`${'go-top-holder'} ${className}`}>
        <a href="#" className="btn-go-top"></a>
      </div>
    </div>
  );
}
