import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { RecruitModel } from '../model/recruit-model';
import { lever_fetcher } from '@/service/lever-api';
import { organizeAndCountLeverCategories } from '@/hooks/organizeLeverCategories';

export function useLeverAPI(endpoint: string) {
  const { data, error } = useSWR(endpoint, lever_fetcher);

  // const categoryList = data ? organizeLeverCategories(data.totalPosting) : null;
  const categoryList = data ? organizeAndCountLeverCategories(data.totalPosting) : null;
  const totalPosting = data && data.totalPosting ? data.totalPosting : null;
  const totalPostingCount = totalPosting ? totalPosting.length : 0;

  return { categoryList, totalPosting, totalPostingCount, error };
}
