import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-creative';

import { Navigation, Pagination, EffectFade, Autoplay, EffectCreative } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLayoutEffect, useState } from 'react';
import { GameSlide } from './GameSlide';
import { useMatchMedia } from '@/hooks/useMatchMedia';
import { GameModel } from '@/model/game-model';

type Props = {
  data: GameModel.Game[];
};
export const GameSlider = ({ data }: Props) => {
  const [sliderIndex, setSliderIndex] = useState(1);
  const [swiper, setSwiper] = useState<any>();
  const { VIEW_PC, VIEW_MOBILE, VIEW_TABLET } = useMatchMedia();

  useLayoutEffect(() => {
    if (!data || !swiper) return;
    swiper.update();
  }, [swiper, data]);

  return (
    <section className="game-slider">
      {data.length > 0 && (VIEW_PC || VIEW_TABLET) && (
        <>
          <Swiper
            modules={[Navigation, Pagination, EffectFade, Autoplay, EffectCreative]}
            direction="vertical"
            pagination={{
              clickable: true,
              renderBullet: (index, className) =>
                '<span class="' + className + '"><em>' + (data[index].title ?? '') + '</em></span>',
            }}
            autoplay={{ delay: 7000 }}
            loop={true}
            effect={'creative'}
            speed={1000}
            creativeEffect={{
              perspective: true,
              prev: {
                shadow: true,
                translate: [0, '-40%', -10],
              },
              next: {
                translate: [0, '100%', 0],
              },
            }}
            onSlideChange={(s) =>
              setSliderIndex(() => {
                //if idx 가 length보다 크면 %length
                let activeIdx = s.activeIndex;
                if (activeIdx > data.length) {
                  activeIdx = activeIdx % data.length;
                }
                return Number(activeIdx);
              })
            }
            onSwiper={setSwiper}>
            {data
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((banner, i) => (
                <SwiperSlide key={i}>
                  {(slideData) => <GameSlide banner={banner} slideData={slideData} />}
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="swiper-pagination-fraction">
            <span className="swiper-pagination-current">{sliderIndex}</span>
            <span>{data.length}</span>
          </div>
        </>
      )}
      {data.length > 0 && VIEW_MOBILE && (
        <>
          <Swiper
            modules={[Navigation, Pagination, EffectFade, Autoplay, EffectCreative]}
            pagination={{
              type: 'fraction',
            }}
            autoplay={{ delay: 15000 }}
            loop={true}
            navigation={true}
            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [-200, 0, -10],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            onSwiper={setSwiper}>
            {data
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((banner, i) => (
                <SwiperSlide key={i}>
                  {(slideData) => <GameSlide banner={banner} slideData={slideData} />}
                </SwiperSlide>
              ))}
          </Swiper>
        </>
      )}
    </section>
  );
};
