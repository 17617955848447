import { ROUTES } from '@/constants';
import { siteMap } from '@/siteMap';
import { Trans, useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

export function HomeAbout() {
  const { t } = useTranslation('home');
  const { ref: aboutRef, inView: aboutInView } = useInView({
    threshold: 0.8,
  });
  return (
    <section className={`section-about in-view--${aboutInView}`} ref={aboutRef}>
      <div id="home-about"></div>
      <Link to={ROUTES['/about']} className="btn-about">
        <em>about</em>
        <i className="logo-small" />
        <i className="logo-large" />
        <span>
          <Trans t={t} i18nKey={'neo.info'} />
        </span>
        <i className="red-dot" />
      </Link>
    </section>
  );
}
