import { Link } from 'react-router-dom';

type Props = {
  url: string, // /로 시작 /로 끝나야 함 ex) /media/story/
  seq: number, // 상세 페이지용 seq
  category: string;
  title: string,
  img: string | undefined;
  contents: string;
  date?: string;
};


const GalleryListItem = ({ url, seq, category, title, img, contents, date }: Props) => {
  
  return (
    // 상위 wrapper는 gallery-list 클래스명을 가져야 하며 
    // .gallery--break-tablet 추가할 경우 반응형 1단계 추가 적용
    <div className="gallery-list-item">
      <Link to={url + seq} className="gallery-item-router"></Link>
      {/* img */}
      <div className="img-box">
        {img && <img src={img} alt="" />}
      </div>
      {/* content */}
      <div className="gallery-content-box">
        <div className="category">{category}</div>
        <strong className="title" dangerouslySetInnerHTML={{ __html: title}}></strong>
        <p className="contents" dangerouslySetInnerHTML={{ __html: contents}}></p>
        { date && (
          <p className="date">{date?.split(" ")[0]}</p>
        )}
      </div>
    </div>
  )
};

export default GalleryListItem;