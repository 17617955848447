import { useMatchMedia } from '@/hooks/useMatchMedia';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { API_PATH } from '@/constants';
import { useAPI } from '@/hooks/useAPI';
import { CareersModel } from '@/model/careers-model';
import { JoinCard } from '@/components/JoinCard';
import styles from './JoinCardList.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

type Props = {
  setInview: (viewValue: CareersModel.CareerBookMark) => void;
};

export const JoinCardList = ({ setInview }: Props) => {
  const { t } = useTranslation('careers');
  const { VIEW_MOBILE } = useMatchMedia();
  const { data: response } = useAPI<CareersModel.InsideRes>(API_PATH['{lang}/careers/careers']);
  const { ref, inView, entry } = useInView({
    threshold: 0.45,
  });
  useEffect(() => {
    if (inView) {
      setInview('join');
      return;
    }
  }, [inView]);
  return (
    <section className={cx('section-join')} ref={ref}>
      <div className={cx('bookmark')} id="join" />
      <p className={cx('sub-title')}>
        <p>합류절차</p>
      </p>
      <div className={cx('content-join')}>
        {response &&
          response.data.map((item, i) => {
            const image = item.careersImageUrl;
            const [title, content] = item.urlId.split('|');
            const formattedTitle = `${(i + 1).toString().padStart(2, '0')}. ${title}`;
            return (
              <div key={i}>
                <JoinCard title={formattedTitle} content={content} image={image} />
              </div>
            );
          })}
      </div>
    </section>
  );
};
