import { MainNav } from '../../components/MainNav';
import { PageHeader } from '@/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { AppFooter } from '@/components/AppFooter';
import { Link, useParams } from 'react-router-dom';
import { useAPI } from '@/hooks/useAPI';
import { API_PATH, ROUTES } from '@/constants';
import { supplant } from '@/util/string';
import { InvestorsModel } from '@/model/inverstors-model';
import pdf from '@/assets/images/investors/pdf.png';
import download from '@/assets/images/investors/download.png';
import { HashLink } from 'react-router-hash-link';

export function InvestorView() {
  const { t, i18n } = useTranslation('etc');
  let { seq } = useParams<{ seq: string }>();

  // const fetcher = async () => {
  const { data: response, error } = useAPI<InvestorsModel.AnnouncementRes>(
    supplant(`${API_PATH['{lang}/investors/announcement/{seq}']}`, {
      seq: seq,
    })
  );

  return (
    <div className="page-careers-story">
      <MainNav />
      <PageHeader title="INVESTORS">
        <BreadCrumbItem content="INVESTORS" url="/investors" />
        <BreadCrumbItem content="공고" url="/investors#announce" />
      </PageHeader>
      <div className="sub-page-container">
        <section className={`section-view`}>
          {response && (
            <div className="view-contentBox">
              <div className="view-header">
                <p className="title">{response.data.title}</p>
                <p className="date">
                  {response.data.regDate
                    .substring(0, 8)
                    .replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}
                </p>
              </div>
              <div
                className="view-body"
                dangerouslySetInnerHTML={{ __html: response.data.contents }}
              />
              {response.data.pdfFileUrl && (
                <a className="download-btn" href={response.data.pdfFileUrl} target="_blank">
                  <button className="materials">
                    <img className="icon" src={pdf} alt={pdf} />
                    <img src={download} alt="" />
                  </button>
                </a>
              )}
            </div>
          )}
          <div className="view-nav">
            {response?.prevSeq && (
              <div className="before">
                <Link to={'/investors/announcement/' + response.prevSeq}>
                  <p className="mobile">{t('bt.previous')}</p>
                  <p className="pc">{t('bt.previous')}</p>
                </Link>
              </div>
            )}
            {!response?.prevSeq && (
              <div className="before none">
                <a href="">
                  <p className="mobile">{t('bt.previous')}</p>
                  <p className="pc">{t('bt.no-prev-article')}</p>
                </a>
              </div>
            )}
            <div className="list">
              <HashLink to={ROUTES['/investors'] + '#announce'}>
                <p>목록보기</p>
              </HashLink>
            </div>
            {response?.nextSeq && (
              <div className="next">
                <Link to={'/investors/announcement/' + response.nextSeq}>
                  <p className="mobile">{t('bt.next')}</p>
                  <p className="pc"> {t('bt.next')}</p>
                </Link>
              </div>
            )}
            {!response?.nextSeq && (
              <div className="next none">
                <a href="">
                  <p className="mobile">{t('bt.next')}</p>
                  <p className="pc">{t('bt.no-next-article')}</p>
                </a>
              </div>
            )}
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}

export default InvestorView;
