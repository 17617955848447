import { MainNav } from '../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';
import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';

import Stock from './Stock';
import Financial from './Financial';
import Earnings from './Earnings';
import Disclosure from './Disclosure';
import Announcement from './Announcement';
import { API_PATH } from '@/constants';
import { useAPI } from '@/hooks/useAPI';
import { InvestorsModel } from '@/model/inverstors-model';
import { StickyGoTop } from '@/components/StickyGoTop';
import { StickyBookMark } from './StickyBookMark';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
export type investors = 'stock' | 'financial' | 'earnings' | 'disclosure' | 'announcement';

export type TInvestors = 'stock' | 'financial' | 'earnings' | 'disclosure' | 'announcement';
const InvestorsPage = () => {
  const { data: res } = useAPI<InvestorsModel.InvestorsRes>(`${API_PATH['{lang}/investors']}`);
  const [inViewValue, setInViewValue] = useState<investors>('stock');
  const { i18n } = useTranslation();
  return (
    <div className="page-investors">
      <Helmet>
        <title>네오위즈 - 네오위즈, 세상이 즐거워지는 마법</title>
      </Helmet>
      <MainNav />
      <PageHeader title="INVESTORS">
        <BreadCrumbItem content="INVESTORS" url="/investors" />
      </PageHeader>

      {res?.data && (
        <div className="sub-page-container">
          <StickyBookMark inView={inViewValue} />
          <Stock data={res.data.stock} setInview={setInViewValue} />
          <StickyGoTop>
            <Financial data={res.data.finances} setInview={setInViewValue} />
            <Earnings data={res.data.earningsRelease} setInview={setInViewValue} />
            {i18n.language === 'ko' && <Disclosure setInview={setInViewValue} />}
            {i18n.language === 'ko' && <Announcement setInview={setInViewValue} />}
          </StickyGoTop>
        </div>
      )}
      <AppFooter />
    </div>
  );
};

export default InvestorsPage;
