import { siteMap } from '@/siteMap';
import footerCi from '@images/footer/footer-ci.png';
import { Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

export function AppFooter() {
  const [dropDownOpen, toggleDropdown] = useState(false);
  const dropDownClose = () => toggleDropdown(false);

  const compnayInfoURL = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1208714245';

  useEffect(() => {
    document.addEventListener('click', dropDownClose);
    return () => {
      document.removeEventListener('click', dropDownClose);
    };
  }, []);

  return (
    <footer>
      <div className="links">
        <div className="holder">
          <div className="sns">
            <a
              href="https://www.facebook.com/NEOWIZofficial/"
              className="btn-foot-social facebook"
              target="_blank">
              facebook
            </a>
            <a
              href="https://www.instagram.com/neowiz_official/"
              className="btn-foot-social instagram"
              target="_blank">
              instagram
            </a>
            <a
              href="https://www.youtube.com/channel/UCPxSQrpLyon5LA_19oYmaDQ"
              className="btn-foot-social youtube"
              target="_blank">
              youtube
            </a>
          </div>

          <div className={`family-site-links open--${dropDownOpen}`}>
            <a
              href="#"
              className="btn-drop-down"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                toggleDropdown((prev) => !prev);
              }}>
              <span>Family Sites</span>
              <i />
            </a>
            <div className="drop-down-menu">
              <a href="//www.nwhcorp.com/" target="_blank">
                NEOWIZ HOLDINGS
              </a>
              <a href="//www.gameon.co.jp/" target="_blank">
                GameOn
              </a>
              <a href="//www.magictree.org/" target="_blank">
                FOUNDATION
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="policy">
        <div className="footer-sub-links">
          {siteMap.map(({ path, name }, i) => (
            <Fragment key={i}>
              <NavLink to={path}>{name}</NavLink>
              {name === 'investors' && <br className="br-mobile" />}
            </Fragment>
          ))}
        </div>
        <div className="policy-links">
          <a href="/policy/personal" className="btn-privacy">
            개인정보처리방침
          </a>
          <a href="/policy/cctv">영상정보처리기기 설치운영 관리방침</a>
        </div>
      </div>
      <div className="address-info">
        <img src={footerCi} className="neowiz-ci" alt="Neowiz" />

        <address className="address">
          <span className="company-number">사업자등록번호 120-87-14245</span>
          <span className="business-number">통신판매업 신고번호 제 2010-경기성남-0562호</span>
          <a
            href={compnayInfoURL}
            className="btn-company-info"
            onClick={(e) => {
              e.preventDefault();
              window.open(
                compnayInfoURL,
                'bizinfo',
                'width=720,height=650,status=1,scrollbars=yes,resizable=yes'
              );
              // trackOutboundLink('Main_Footer_BizInfo', '0');
              return false;
            }}>
            사업자정보확인
          </a>
          <p className="copyright">&copy; NEOWIZ All rights reserved.</p>
        </address>
      </div>
    </footer>
  );
}
