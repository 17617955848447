import { ISiteMap } from '@/siteMap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { LangChanger } from './LangChanger';
import { SubLink } from './SubLink';

type Props = {
  siteMap: ISiteMap;
};
export const LinkPc = ({ siteMap }: Props) => {
  const [arrowPos, setArrowPos] = useState(-9999);
  const { t } = useTranslation('common');
  const mainLinkMouseOver = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (!JSON.parse(e.currentTarget.dataset.arrow ?? '')) return setArrowPos(() => -9999);
    const parentPos = e.currentTarget.parentElement?.getBoundingClientRect();
    const pos = e.currentTarget.getBoundingClientRect();
    if (!parentPos || !pos) return;
    const center = pos.x - parentPos.x + pos.width / 2;
    setArrowPos(() => center);
  };

  return (
    <>
      <div className="links">
        <i className="ico-arrow" style={{ transform: `translateX(${arrowPos || 0}px)` }} />
        <ul className="main-link">
          {siteMap.map(({ name, path, subMenu }, mainIdx) => {
            const hasSub = subMenu && subMenu.length > 0;

            return (
              <li key={mainIdx} data-arrow={hasSub} onMouseEnter={mainLinkMouseOver}>
                <NavLink
                  to={path}
                  className={({ isActive }) => {
                    return isActive ? 'active' : ''; // 활성 여부
                  }}>
                  <span>{t(name)}</span>
                </NavLink>
                {hasSub && (
                  <ul className="sub-link">
                    {subMenu.map((sub, subIdx) => (
                      <li key={subIdx}>
                        <SubLink link={sub} />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="underline" />
      <div className="open-gb" />
      <LangChanger />
    </>
  );
};
