import ContentListItem from '@/components/ContentListItem';
import { NewsModel } from '@/model/news-model';
import { Link } from 'react-router-dom';

type Props = {
  data: NewsModel.NewsList[];
};
export const NewsList = ({ data }: Props) => {
  return (
    <div className="story-list">
      {data.map((item, i) => {
        return (
          <Link key={i} to={`/media/press/${item.seq}`}>
            <ContentListItem
              title={item.subject}
              img={item.imageUrl}
              contents={item.contents}
              writer={undefined}
              date={item.regDate.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3')}
            />
          </Link>
        );
      })}
    </div>
  );
};
