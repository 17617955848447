import { CareersModel } from '@/model/careers-model';

export function faqJsonParse(originalData: CareersModel.FAQData) {
  const result: CareersModel.FAQItem[] = [];

  for (let key in originalData['TYPE']) {
    const newEntry: CareersModel.FAQItem = {
      category: originalData['TYPE'][key],
      title: originalData['QUESTIONS'][key],
      content: originalData['ANSWER'][key],
    };
    result.push(newEntry);
  }

  return result;
}
