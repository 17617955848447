import { ILinkInfo } from '@/siteMap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

type Props = {
  link: ILinkInfo;
};

export const SubLink = ({ link }: Props) => {
  const { t } = useTranslation('common');
  const loc = useLocation();

  const currentUrlHash = loc.hash.slice(1);
  const linkPropsHash = link.path.split('#').pop();
  const isActiveHash = currentUrlHash !== 'game-list' && currentUrlHash === linkPropsHash;
  const pathOnly = link.path.split('?')[0];
  const isActivePath = pathOnly === loc.pathname;

  return (
    <>
      {link.target === '_blank' ? (
        <a target={link.target} href={link.path}>
          <span>{t(link.name)}</span>
          <i className="ico-out-link" />
        </a>
      ) : (
        <NavHashLink
          to={link.path}
          target={link.target}
          smooth
          timeout={3000}
          className={({ isActive }) => {
            if (currentUrlHash && isActiveHash) {
              return 'active';
            } else if (isActive && isActivePath) {
              return 'active';
            } else {
              return '';
            }
          }}>
          <span>{t(link.name)}</span>
        </NavHashLink>
      )}
    </>
  );
};
