import { MainNav } from '../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';
import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FloatingBanner } from './FloatingBanner';
import { Hero } from './Hero';
import { JoinCardList } from './JoinCardList';
import { WelfareCardList } from './WelfareCardList';
import { FaqCardList } from './FaqCardList';
import { CareersModel } from '@/model/careers-model';
import { StickyBookMark } from './StickyBookMark';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const CareersInsidePage = () => {
  const [inViewValue, setInViewValue] = useState<CareersModel.CareerBookMark>('join');
  return (
    <div className={cx('page-careers')} id="inside">
      <Helmet>
        <title>네오위즈 채용</title>
        <meta
          name="description"
          content="세상을 놀이터로 만들어 가기 위한 당신의 꿈과 열정을 응원합니다."></meta>
        <meta
          name="keywords"
          content="네오위즈플레이스튜디오, 네오위즈, 채용, 경력, 신입, 인턴, 게임잡, 판교, 넥슨, 엔씨소프트, 넷마블, 한게임 , 컴투스, 네이버, 구글, 크래프톤, 게임회사, 개발자, 게임"
        />
        <meta name="subject" content="네오위즈 채용" />
        <meta name="author" content="네오위즈" />
        <meta name="title" content="네오위즈 채용" />
        <meta
          name="google-site-verification"
          content="5_gaoMNvKFalSfU4f5z-J0tX5bOhpoCJp1U55cB0mvc"
        />
      </Helmet>
      <MainNav />
      <PageHeader title="CAREERS">
        <BreadCrumbItem content="CAREERS" url="/careers" className="undefined" />
      </PageHeader>
      <div className={cx('sub-page-container')}>
        <StickyBookMark inView={inViewValue} />
        <JoinCardList setInview={setInViewValue} />
        <WelfareCardList setInview={setInViewValue} />
        <FaqCardList setInview={setInViewValue} />
      </div>
      <FloatingBanner />
      <AppFooter />
    </div>
  );
};

export default CareersInsidePage;
