import { Fragment } from 'react';
import styles from './WelfareCard.module.scss';
import classNames from 'classnames/bind';
import { HtmlLineBreakParser } from './HtmlLineBreakParser';

const cx = classNames.bind(styles);
type Props = {
  title: string;
  content: string;
  image: string;
};

export const WelfareCard = ({ title, content, image }: Props) => {
  return (
    <div className={cx('welfare')}>
      <div className={cx('content-box')}>
        <div className="image-container">
          <img className={cx('image')} src={image} alt="welfare-image" />
        </div>
        <p className={cx('title')}>{title}</p>
        <p className={cx('content')}>{HtmlLineBreakParser(content)}</p>
      </div>
    </div>
  );
};
