import { useInView } from 'react-intersection-observer';

import newsBg from '@images/home/bg-news.jpg';
import investBg from '@images/home/bg-invest.jpg';
import { siteMap } from '@/siteMap';
import { Link, Route } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { useTranslation } from 'react-i18next';

export function HomeNews() {
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });
  const { i18n } = useTranslation();

  return (
    <section className={`section-news in-view--${inView}`} ref={ref}>
      {i18n.language === 'ko' ? (
        <Link to={ROUTES['/media']}>
          <img src={newsBg} />
          <span>
            MEDIA
            <i className="ico-red-dot" />
          </span>
        </Link>
      ) : (
        <Link
          to={ROUTES['/careers']}
          onClick={() => {
            i18n.changeLanguage('ko');
          }}>
          <img src={newsBg} />
          <span>
            RECRUITING
            <i className="ico-red-dot" />
          </span>
        </Link>
      )}
      <Link to={ROUTES['/investors']}>
        <img src={investBg} />
        <span>
          INVESTORS
          <i className="ico-red-dot" />
        </span>
      </Link>
    </section>
  );
}
