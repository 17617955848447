import throttle from 'lodash/throttle';

export let IS_MOBILE = false;

const shouldMobile = throttle(function () {
  if (window.matchMedia('(max-width: 870px)').matches) {
    IS_MOBILE = true;
  } else {
    IS_MOBILE = false;
  }
}, 250);

shouldMobile();
window.addEventListener('resize', shouldMobile);
