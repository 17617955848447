import { useTranslation } from 'react-i18next';
import { SearchBar } from '@/components/SearchBar';
import { PaginationPage } from '@/components/PaginationPage';
import { NewsModel } from '@/model/news-model';
import { API_PATH } from '@/constants';
import { NoResult } from '@/components/NoResult';
import { TopNews } from './TopNews';
import { NewsList } from './NewsList';
import { useQueryBoard } from '@/hooks/useQueryBoard';
import { Link } from 'react-router-dom';

interface Props {
  onlyTopBanner: boolean;
}

const Releases = ({ onlyTopBanner }: Props) => {
  const { t } = useTranslation('news');

  const {
    setSearchText,
    setCurrentPage,
    swrData: { data: res, error },
    searchParams,
  } = useQueryBoard<NewsModel.ListsRes>(`${API_PATH['{lang}/media/press']}`);

  const isLoading = !res && !error;
  const listExist = res && res?.data.length >= 1;
  const showBanners = Number(searchParams.get('page')) === 1 && !searchParams.get('searchText');

  return (
    <section className={`section-releases ${onlyTopBanner ? "only-top-banner" : ""}`}>
      <p className="section-title">{t('title')}</p>
      <SearchBar submitSearch={setSearchText} redirect={onlyTopBanner ? true : false} link="/media/press"/>
      {!listExist && !isLoading && <NoResult />}

      {res && res.data && showBanners && <TopNews data={res.data.slice(0, 4)} />}

      { res && onlyTopBanner && <Link to="/media/press" className="btn-more-detail">+ 더보기</Link>}

      { !onlyTopBanner && (
        <>
          <div className="story-list">
            {res && res.data && <NewsList data={showBanners ? res.data.slice(4) : res.data} />}
          </div>
          {res && (
            <PaginationPage
              setCurrentPage={setCurrentPage}
              totalPage={res.totalPage}
              currentPage={res.page}
            />
          )}
        </>
      )}
    </section>
  );
};

export default Releases;
