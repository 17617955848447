import { MainNav } from '../../../components/MainNav';
import { AppFooter } from '@/components/AppFooter';

import { PageHeader } from '@/components/PageHeader';
import { BreadCrumbItem } from '@/components/BreadCrumbItem';
import { useTranslation } from 'react-i18next';
import Partnership from './Partnership';
import { ROUTES } from '@/constants';

const ContactUsPartnershipPage = () => {
  const { t } = useTranslation('contact-us');
  return (
    <div className="page-partnership">
      <MainNav />
      <PageHeader title="CONTACT US">
        <BreadCrumbItem content="CONTACT US" url={ROUTES['/contact-us']} />
        <BreadCrumbItem content="PARTNERSHIP" url={ROUTES['/contact-us/partnership']} />
      </PageHeader>
      <div className="sub-page-container">
        <Partnership />
      </div>
      <AppFooter />
    </div>
  );
};

export default ContactUsPartnershipPage;
