import styles from './FloatingBanner.module.scss';
import classNames from 'classnames/bind';
import { useLeverAPI } from '@/hooks/useLeverAPI';
import { RecruitModel } from '@/model/recruit-model';
import { useTranslation } from 'react-i18next';
import { API_PATH } from '@/constants';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import bell from '@/assets/images/careers/flt-banner1.png';
import arrow from '@/assets/images/careers/flt-banner2.png';

const cx = classNames.bind(styles);
interface Props {}

export const FloatingBanner = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation('careers');
  const { totalPostingCount, error } = useLeverAPI(API_PATH['lever']);

  useEffect(() => {
    if (error) {
      setIsError(true);
      setIsLoading(false);
    } else if (totalPostingCount) {
      // Loading이 끝나고, categoryList와 totalPosting가 존재할 때만 Loading을 false로 변경
      setIsLoading(false);
    }
  }, [totalPostingCount, error]);
  return (
    <div className={cx('float-main')}>
      <div className={cx('float-inner')}>
        <img className={cx('bell-img')} src={bell} alt="공고" />
        <div className={cx('opening-group')}>
          <div className={cx('item1')}>진행 중 공고</div>
          <div className={cx('item2')}>
            {isLoading ? <div className={cx('spinner')} /> : totalPostingCount}
          </div>
          <div className={cx('item3')}>개</div>
        </div>
        <ul className={cx('btn-group')}>
          <li className={cx('btn', 'talent')}>
            <a
              href="https://jobs.lever.co/neowiz/c74ffadf-1e4e-4a3e-8ec3-fde9cc8f2aa3/apply"
              target="_blank">
              <span>인재풀 등록</span>
              <img src={arrow} alt="arrow" />
            </a>
          </li>
          <li className={cx('btn')}>
            <Link to="/careers/recruit">
              <span>전체 공고보기</span>
              <img src={arrow} alt="arrow" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
