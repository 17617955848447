import { useState, useEffect } from 'react';
import { CareersModel } from '@/model/careers-model';
import styles from './WelfareCategoryFilter.module.scss';
import classNames from 'classnames/bind';
import { Link, useSearchParams } from 'react-router-dom';

const cx = classNames.bind(styles);

type Props = {
  selectedCategory: string;
  setSelectedCategory: (setWelfareCategory: CareersModel.WelfareCategory) => void;
};

export const WelfareCategoryFilter = ({ selectedCategory, setSelectedCategory }: Props) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const listParam = searchParams.get('list');
    switch (listParam) {
      case 'family':
        setSelectedCategory('FACILITY');
        break;
      case 'worknlife':
        setSelectedCategory('BALANCE');
        break;
      case 'health':
        setSelectedCategory('HEALTH');
        break;
      default:
        setSelectedCategory('FACILITY');
        break;
    }
  }, [searchParams, setSelectedCategory]);

  useEffect(() => {
    setTimeout(() => {
      if (searchParams.has('list')) {
        const element = document.getElementById('welfare-category-filter');
        if (element) {
          const offsetPosition = element.offsetTop;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }, 300);
  }, [searchParams]);

  return (
    <div id="welfare-category-filter" className={cx('category-filter')}>
      <p>네오위즈 혜택</p>
      <div className={cx('select-filter')}>
        <Link
          to="/careers?list=family"
          className={cx({ active: selectedCategory === 'FACILITY' })}
          onClick={() => setSelectedCategory('FACILITY')}>
          <div>FAMILY</div>
        </Link>
        <span></span>
        <Link
          to="/careers?list=worknlife"
          className={cx({ active: selectedCategory === 'BALANCE' })}
          onClick={() => setSelectedCategory('BALANCE')}>
          <div>WORK&LIFE</div>
        </Link>
        <span></span>
        <Link
          to="/careers?list=health"
          className={cx({ active: selectedCategory === 'HEALTH' })}
          onClick={() => setSelectedCategory('HEALTH')}>
          <div>HEALTH</div>
        </Link>
      </div>
    </div>
  );
};
