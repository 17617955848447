import { ROUTES } from '@/constants';
import { useTranslation, Trans } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

export function HomeContactUs() {
  const { t } = useTranslation('home');
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <section className={`section-contact-us in-view--${inView}`} ref={ref}>
      <div className={`holder skull`}>
        <div className="bg"></div>
        <div className="slogan">
          <h3>CONTACT US</h3>
          <p>
            <Trans t={t} i18nKey={'contact.us.text1'} />
          </p>
          <Link to={ROUTES['/contact-us']} className="btn-more">
            <span>{t('bt.contact.us')}</span>
            <i></i>
          </Link>
        </div>
        <span className="character-skull"></span>
      </div>
    </section>
  );
}
