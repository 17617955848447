import { API_PATH } from '@/constants';
import { AboutModel } from '@/model/about-model';
import { Fragment, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { groupByToArray } from '@/util/collection';
import { toggleSiblingsClass } from '@/util/dom';
import { useAPI } from '@/hooks/useAPI';

const AboutHistory = () => {
  const { t } = useTranslation('about');
  const divRef = useRef<HTMLDivElement[]>([]);
  const linkRef = useRef<HTMLAnchorElement[]>([]);

  const { data: response } = useAPI<AboutModel.HistoryRes>(API_PATH['{lang}/about/history']);

  const groupByData = (data: AboutModel.History[]) => {
    return groupByToArray(data, (item) => item.year).sort((a, b) => Number(b.key) - Number(a.key));
  };

  return (
    <section id={'history'} className={`section-history`}>
      <h3 className="sub-title">
        <Trans t={t} i18nKey={'history.title'} />
      </h3>
      <div className="history-box">
        <div className="year">
          {response &&
            groupByData(response.data).map(({ key: year }, i) => (
              <a
                key={i}
                ref={(el) => {
                  if (el) linkRef.current[i] = el;
                }}
                className={i === 0 ? 'selected' : ''}
                onClick={(e) => {
                  e.preventDefault();
                  toggleSiblingsClass(e, 'selected', false);
                  if (divRef.current.length < 1) return;
                  divRef.current.forEach((el) => el.classList.remove('show'));
                  divRef.current[i].classList.add('show');
                }}>
                {year}
                <i />
              </a>
            ))}
        </div>
        <div className="info">
          {response &&
            groupByData(response.data).map(({ group }, i) => (
              <div
                key={i}
                ref={(el) => {
                  if (el) divRef.current[i] = el;
                }}
                className={`info-content ${i === 0 ? 'show' : ''}`}>
                <div className="scroll-box">
                  {group.map(({ month, title }, j) => (
                    <Fragment key={j}>
                      <h4 data-month={month}>{String(month).padStart(2, '0')}</h4>
                      <p>{title}</p>
                    </Fragment>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default AboutHistory;
