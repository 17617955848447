import { ROUTES } from '@/constants';
import { useTranslation } from 'react-i18next';

export function LangChanger() {
  const { i18n } = useTranslation();
  return (
    <div className="lang">
      <a
        onClick={() => i18n.changeLanguage('ko')}
        className={`${i18n.language !== 'en' ? 'on' : ''}`}>
        KR
      </a>
      <b />
      <a
        onClick={() => i18n.changeLanguage('en')}
        className={`${i18n.language === 'en' ? 'on' : ''}`}>
        EN
      </a>
    </div>
  );
}
