import { vhHack } from '@/util/vhHack';
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

export function useMatchMedia() {
  const [currentView, viewportMobile] = useState<'PC' | 'MOBILE' | 'TABLET'>('PC');

  const viewMatch = throttle(function () {
    if (window.matchMedia('(max-width: 870px)').matches) {
      vhHack();
      viewportMobile(() => 'MOBILE');
      return;
    }

    if (window.matchMedia('(max-width: 1419px)').matches) {
      vhHack();
      viewportMobile(() => 'TABLET');
      return;
    }

    viewportMobile(() => 'PC');
  }, 250);

  useEffect(() => {
    viewMatch();
    window.addEventListener('resize', viewMatch);
    return () => {
      window.removeEventListener('resize', viewMatch);
    };
  }, []);

  return {
    VIEW_PC: currentView === 'PC',
    VIEW_TABLET: currentView === 'TABLET',
    VIEW_MOBILE: currentView === 'MOBILE',
  };
}
