/**
 * Usage:
 * supplant("I'm {age} years old!", { age: 29 });
 * output: I'm 29 years old!
 */
export function supplant<T>(string: String, o: T) {
  return string.replace(/{([^{}]*)}/g, function (a, b: keyof T) {
    let r = o[b];
    return String(typeof r === 'string' || typeof r === 'number' ? r : a);
  });
}

export function simpleUUID(prefix = '') {
  return `${prefix}${(Math.random() * Date.now()).toString(32).substr(2)}`;
}
